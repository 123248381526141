/* Import Swiper styles */
@import 'swiper/css';
@import 'swiper/css/navigation';
@import 'swiper/css/pagination';
@import 'swiper/css/scrollbar';
@import "~react-image-gallery/styles/css/image-gallery.css";
@import 'react-accessible-accordion/dist/fancy-example.css';


video {
    cursor: pointer;
}

.partnerSec .swiper {
    /* overflow: visible; */
    padding-bottom: 50px;
}

.partnerSec .swiper-pagination {
    bottom: -20px !important;
}

.partnerSec .swiper-pagination .swiper-pagination-bullet-active {
    background: #072654 !important;
    position: relative;
}

.partnerSec .swiper-pagination .swiper-pagination-bullet {
    background: #D3D3D3;
    width: 10px;
    height: 10px;
    margin: 6px !important;

}

.partnerSec .swiper-pagination .swiper-pagination-bullet-active:before {
    content: " ";
    position: absolute;
    left: 50%;
    top: 50%;
    border: 1px solid #072654;
    width: 14px;
    height: 14px;
    border-radius: 50px;
    transform: translate(-50%, -50%);
}

.eventSec .swiper {
    overflow: visible;
    padding: 0 20px;
}

.eventSec .container {
    overflow: hidden;
}

.eventSec .swiper-button-prev,
.eventSec .swiper-button-next {
    top: -60px;
    background: #fff;
    border-radius: 50px;
    width: 50px;
    height: 50px;
    z-index: 2;
}

.eventSec .swiper-button-prev {
    left: initial;
    right: 75px;
}

.eventSec .swiper-button-prev:after {
    font-size: 0px;
    background-image: url("/public/assets/images/leftslide.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    width: 20px;
    height: 20px;
}

.eventSec .swiper-button-next:after {
    font-size: 0px;
    background-image: url("/public/assets/images/rightslide.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    width: 20px;
    height: 20px;
}

.testimonialSec .swiper {
    overflow: visible;
}

/* .partnerSec .swiper-slide {
    filter: grayscale(100%);
    opacity: 0.2;
    transition: all 0.5s ease-in-out;
} */

.partnerSec .swiper-slide.swiper-slide-prev,
.partnerSec .swiper-slide.swiper-slide-next {
    opacity: 1;
    transform: scale(1);
}

.partnerSec .swiper-slide.swiper-slide-next+.swiper-slide {
    opacity: 0.5;
    transform: scale(0.95);
}

.partnerSec .swiper-slide:has(+ .swiper-slide.swiper-slide-prev) {
    opacity: 0.5;
    transform: scale(0.95);
}

.partnerSec .swiper-slide.swiper-slide-active {
    filter: none;
    opacity: 1;
    /* transform: scale(1.12); */
}

/* ------------------------Community Challenges  page css------------------------ */
.communityIntro-section,
.communityFlameUni {
    background-color: #F0F0F0 !important;
}

.communityWhyOxfordSec {
    background-image: url("../public/assets/images/org-bg.png") !important;
}

.communityConference .confere-box {
    border: 3px solid #F9A21B;
}

.communityConference .confere-detail .confere-box:before {
    background-color: #F9A21B !important;
}

.communityConference .confere-box span.bgColorText {
    background-color: #000000 !important;
}

.communityConference .confere-reg p span {
    background-color: #ffffff !important;
    color: #F9A21B !important;
}

.communityConference .confere-reg h3,
.communityConference .confere-reg p {
    color: #ffffff !important;
}

.communityConference .registerBtn {
    background-color: #ffffff;
    color: #F9A21B;
}

.communityFlameUni .communityFlameUni-outer {
    display: grid;
    gap: 170px;
    grid-template-columns: 0.74fr 1.5fr;
}

.communityFlameUni .communityFlameUni-outer .communityFlameUni-innerLeft img {
    padding-bottom: 40px;
    display: block;
}

.communityFlameUni .communityFlameUni-outer .communityFlameUni-innerLeft h3 {
    display: inline-block;
    position: relative;
    padding-bottom: 15px;
}

.communityFlameUni .communityFlameUni-outer .communityFlameUni-innerLeft h3:after {
    content: " ";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    border-radius: 30px;
    background-color: #000;
}

.communityFlameUni .communityFlameUni-outer .communityFlameUni-innerRight .universityImg {
    border-radius: 50px;
    width: 767px;
    position: relative;
    z-index: 1;

}

.communityFlameUni .communityFlameUni-outer .communityFlameUni-innerRight {
    position: relative;
}

.communityFlameUni .communityFlameUni-outer .communityFlameUni-innerRight:before {
    content: " ";
    position: absolute;
    right: 0;
    bottom: -30px;
    border: 4px solid #F9A21B;
    border-radius: 50px;
    width: 767px;
    height: 520px;
    z-index: 0;
}

/* .experienceSec .swiper-slide {
    width: 434px !important;
} */

.experienceSec .experienceBox {
    background-color: #fff;
    border: 2px solid #F9A21B;
    border-radius: 30px;
    padding: 35px;
    box-shadow: 0 0 20px 0 rgba(0 0 0/15%);
    transition: all 0.5s ease-in-out;
    /* width: 434px; */
    min-height: 375px;
}


.experienceSec .experienceBox h5 {
    line-height: 1.3;
}

.experienceSec .experienceBox-inner {
    justify-content: space-between;
    display: flex;
}

.experienceSec .experienceBox-inner img {
    padding: 15px 16px;
    border-radius: 50%;
    height: fit-content;
}

.experienceSec .experienceBox-inner img.nonActive {
    background-color: #F9A21B;

}

.experienceSec .experienceBox-inner img.active {
    background-color: #fff;
    display: none;

}

.experienceSec .swiper-slide-active .experienceBox img.nonActive {
    display: none;
}

.experienceSec .swiper-slide-active .experienceBox img.active {
    display: block;
}

.experienceSec .swiper-slide-active .experienceBox {
    background-color: #F9A21B;
    border: 2px solid #fff;
    box-shadow: 0 0 20px 0 rgba(0 0 0/15%);
}

.experienceSec .swiper-slide-active .experienceBox h5,
.experienceSec .swiper-slide-active .experienceBox p {
    color: #fff !important;
}

.experienceSec .swiper-slide-active .experienceBox-inner img {
    background-color: #fff !important;

}

.experienceSec .swiper {
    overflow: visible;
    padding-bottom: 60px;
}


.communityPartnerSec .swiper-pagination .swiper-pagination-bullet,
.experienceSec .swiper-pagination .swiper-pagination-bullet {
    background: #f0f0f0;
}

.orange_theme .communityPartnerSec .swiper-pagination .swiper-pagination-bullet-active,
.orange_theme .experienceSec .swiper-pagination .swiper-pagination-bullet-active {
    background: #F9A21B !important;
}

.communityPartnerSec .swiper-pagination .swiper-pagination-bullet-active::after {
    display: none;
}

.orange_theme .communityPartnerSec .swiper-pagination .swiper-pagination-bullet-active:before,
.orange_theme .experienceSec .swiper-pagination .swiper-pagination-bullet-active:after {
    border: 1px solid #F9A21B !important;
}


.rewardSec {
    background-color: #F0F0F0;
}

.rewardSec .priceHeading {
    font-size: 40px;
    line-height: 1;
    background-color: #F9A21B;
    display: inline-block;
    padding: 15px 65px 85px;
    border-radius: 65px 65px 0 0;
}

.rewardSec .priceHeading-hsis {
    font-size: 40px;
    line-height: 1;
    background-color: #A51C30;
    color:white;
    display: inline-block;
    padding: 15px 65px 85px;
    border-radius: 65px 65px 0 0;
}

.rewardSec .priceHeading-oxford{
    background-color: #002147;
}

.rewardSec .reward-outer {
    display: grid;
    gap: 40px;
    grid-template-columns: 1fr 1fr 1fr;
    padding-bottom: 30px;
}

.rewardSec .reward-outer-hsis {
    display: grid;
    gap: 10rem;
    grid-template-columns: 1fr 1fr;
    padding: 0 3rem;
    align-items: stretch;
}

.rewardSec .rewardInner {
    text-align: center;
    transform: scale(0.85);
}

.rewardSec .innnerContent h6 {
    font-size: 22px;
    line-height: 1.3;
}

.rewardSec .rewardInner.rewardInnerCenter {
    transform: scale(1.15);
}

.rewardSec .innnerContent {
    border: 3px solid #F9A21B;
    border-radius: 22px;
    padding: 20px 40px 30px;
    background-color: #fff;
    position: relative;
    margin-top: -80px;
}

.rewardSec .innnerContent-hsis {
    border: 3px solid #A51C30;
    border-radius: 22px;
    height: 65%; 
}

.rewardSec .innnerContent-oxford {
    border: 3px solid #002147;
    border-radius: 22px;
    height: 65%; 
}

.rewardSec .innnerContent .innnerContentTop {
    border-image: linear-gradient(to left, #ffffff00, #00000080, #000000, #00000080, #fb4f2200) 1;
    border-bottom: 2px solid;
    margin-bottom: 20px;
    padding-bottom: 20px;
}

.rewardSec .rewardInner .priceTitle {
    font-size: 28px;
    background-image: url("../public/assets/images/rewardFrame.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    display: inline-block;
    width: 375px;
    height: 60px;
    margin-top: -18px;
    position: relative;
    line-height: 2;
}
.rewardSec .rewardInner .priceTitle-oxford {
    background-image: url("../public/assets/images/oxfordMun/rewardFrameOxford.png");
    color:white
}

.rewardSec .rewardInner .priceTitle-hsis {
    background-image: url("../public/assets/images/hsis/rewardFrameHsis.png");
    color:white
}

.blackBarSec {
    background-color: #000;
    padding: 30px 20px;
    text-align: center;
}

.rewardSecondSec {
    background-color: #F0F0F0;
}

.rewardSecondSec h2 {
    text-align: center;
}

.rewardSecondSec .program-outer .program-innerRight ul.progarmDetailList {
    background-color: #F9A21B !important;
}

.rewardSecondSec .program-outer .program-innerRight .registerChall {
    background-color: #000;
    width: 310px !important;
    font-weight: 400;
    cursor: pointer;
    padding: 15px 30px;
    align-items: center;
}

.communityCallToAction .callToAction-inner {
    background-image: url("../public/assets/images/readyBgorg.png") !important;
}

.communityCallToAction .registerBtn {
    background: #fff;
    color: #F9A21B;
}

.communityFaqSec .faqInnerLeft {
    background: #000;
    background-image: none !important;
}

.communityFaqSec .faqInner {
    background: #F0F0F0 !important;
}

.reviewSec .swiper-slide > div {
    position: relative;
    display: inline-flex;
    max-height: 90vh;
}

@media screen and (max-width: 1440px) {
    .rewardSec .priceHeading {
        font-size: 36px;
    }

    .rewardSec .rewardInner .priceTitle {
        font-size: 26px;
    }

    .rewardSec .innnerContent {
        padding: 20px 30px 30px;
    }

    .communityFlameUni .communityFlameUni-outer {
        gap: 100px;
        grid-template-columns: 1fr 1.5fr;
    }

    .communityFlameUni .communityFlameUni-outer .communityFlameUni-innerRight:before {
        right: -30px;
    }

    .fs-42 {
        font-size: 38px !important;
    }


}

@media screen and (max-width: 1024px) {
    .rewardSec .reward-outer {
        padding-bottom: 0px;
        gap: 40px;
        grid-template-columns: 1fr;
    }

    .rewardSec .reward-outer-hsis{
        gap:100px
    }

    .communityFlameUni .communityFlameUni-outer {
        gap: 50px;
        grid-template-columns: 1fr;
    }

    .communityFlameUni .communityFlameUni-outer .communityFlameUni-innerRight .universityImg {
        width: 95%;
    }

    .communityFlameUni .communityFlameUni-outer .communityFlameUni-innerRight:before {
        right: 0;
        width: 95%;
        height: 100%;
    }

    .communityFlameUni .communityFlameUni-outer .communityFlameUni-innerLeft img {
        width: 180px;
        padding-bottom: 30px;
    }

    .fs-42 {
        font-size: 36px !important;
    }

    .fs-18 {
        font-size: 16px !important;
    }

    .programsSec .program-outer {
        display: block !important;
        padding-top: 40px !important;
    }

    .programsSec .program-outer .program-innerLeft {
        margin-right: 0px !important;
        padding-bottom: 40px;
    }

    .programsSec .program-outer .program-innerRight {
        margin-left: 0px !important;
    }

    .programsSec .program-outer .program-innerLeft img {
        width: 100% !important;
    }

    .rewardSec .rewardInner {
        width: 440px;
        margin: 0 auto;
    }

    .rewardSec .priceHeading {
        font-size: 28px;
    }

    .rewardSec .rewardInner .priceTitle {
        font-size: 22px;
        height: 50px;
    }

    .rewardSec .innnerContent h6 {
        font-size: 20px;
    }

}

@media screen and (max-width: 767px) {
    .programsSec.bgBlack .program-outer .program-innerLeft img {
        width: 27px !important;
    }

    .communityFlameUni .communityFlameUni-outer .communityFlameUni-innerRight .universityImg {
        width: 90%;
        border-radius: 30px;
    }

    .communityFlameUni .communityFlameUni-outer .communityFlameUni-innerRight:before {
        right: 15px;
        width: 85%;
        height: 95%;
        border-radius: 30px;
        bottom: -20px;
    }

    .communityFlameUni .communityFlameUni-outer .communityFlameUni-innerLeft img {
        width: 130px;
        padding-bottom: 20px;
    }

    .communityFlameUni .communityFlameUni-outer {
        gap: 30px;
        padding-bottom: 30px;
    }

    .rewardSec .rewardInner {
        width: auto;
        transform: scale(0.75);
    }

    .rewardSec .rewardInner.rewardInnerCenter {
        transform: scale(1);
    }

    .rewardSec .reward-outer {
        gap: 20px;
        padding: 0px;
    }

    .rewardSec .priceHeading {
        font-size: 24px;
    }

    .rewardSec .innnerContent h6 {
        font-size: 18px;
    }

    .rewardSec .rewardInner .priceTitle {
        font-size: 20px;
        height: 48px;
        width: 90%;
    }

    .programsSec .program-outer {
        padding-top: 30px !important;
    }

    .programsSec .program-outer .program-innerRight ul.progarmDetailList {
        padding: 15px 15px;
    }

    .programsSec .program-outer .program-innerRight ul.progarmDetailList li span {
        padding-left: 15px;
    }

    .rewardSecondSec .program-outer .program-innerRight .registerChall {
        width: 260px !important;
        padding: 15px 20px;
    }












}