span.error-message {
    color: #fff;
    right: 14px;
    font-size: 11px;
    position: absolute;
    bottom: 11px;
    background: #d12d2d;
    padding: 2px 15px;
    border-radius: 20px;
}
.form_field{
    position: relative;
}


@font-face {
    font-family: 'Euclid Circular A';
    src: url('../font/italic/EuclidCircularA-BoldItalic.eot');
    src: url('../font/italic/EuclidCircularA-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../font/italic/EuclidCircularA-BoldItalic.woff2') format('woff2'),
        url('../font/italic/EuclidCircularA-BoldItalic.woff') format('woff'),
        url('../font/italic/EuclidCircularA-BoldItalic.ttf') format('truetype'),
        url('../font/italic/EuclidCircularA-BoldItalic.svg#EuclidCircularA-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Euclid Circular A';
    src: url('../font/semiBold/EuclidCircularA-SemiBold.eot');
    src: url('../font/semiBold/EuclidCircularA-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../font/semiBold/EuclidCircularA-SemiBold.woff2') format('woff2'),
        url('../font/semiBold/EuclidCircularA-SemiBold.woff') format('woff'),
        url('../font/semiBold/EuclidCircularA-SemiBold.ttf') format('truetype'),
        url('../font/semiBold/EuclidCircularA-SemiBold.svg#EuclidCircularA-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Euclid Circular A';
    src: url('../font/medium/EuclidCircularA-Medium.eot');
    src: url('../font/medium/EuclidCircularA-Medium.eot?#iefix') format('embedded-opentype'),
        url('../font/medium/EuclidCircularA-Medium.woff2') format('woff2'),
        url('../font/medium/EuclidCircularA-Medium.woff') format('woff'),
        url('../font/medium/EuclidCircularA-Medium.ttf') format('truetype'),
        url('../font/medium/EuclidCircularA-Medium.svg#EuclidCircularA-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Euclid Circular A';
    src: url('../font/medium-italic/EuclidCircularA-MediumItalic.eot');
    src: url('../font/medium-italic/EuclidCircularA-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('../font/medium-italic/EuclidCircularA-MediumItalic.woff2') format('woff2'),
        url('../font/medium-italic/EuclidCircularA-MediumItalic.woff') format('woff'),
        url('../font/medium-italic/EuclidCircularA-MediumItalic.ttf') format('truetype'),
        url('../font/medium-italic/EuclidCircularA-MediumItalic.svg#EuclidCircularA-MediumItalic') format('svg');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Euclid Circular A';
    src: url('../font/regular/EuclidCircularA-Regular.eot');
    src: url('../font/regular/EuclidCircularA-Regular.eot?#iefix') format('embedded-opentype'),
        url('../font/regular/EuclidCircularA-Regular.woff2') format('woff2'),
        url('../font/regular/EuclidCircularA-Regular.woff') format('woff'),
        url('../font/regular/EuclidCircularA-Regular.ttf') format('truetype'),
        url('../font/regular/EuclidCircularA-Regular.svg#EuclidCircularA-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

html {
    overflow-x: hidden;
}

.m-0 {
    margin: 0 !important;
}

.p-0 {
    padding: 0 !important;
}

body {
    font-family: 'Euclid Circular A';
    margin: 0;
    overflow-x: hidden;
}

ul {
    padding: 0;
    margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
    padding: 0;
}

p {
    font-size: 18px;
    line-height: 1.4em;
}

video::-webkit-media-controls {
    display: block;
}



.secTitle {
    font-size: 70px;
    line-height: 1;
    margin: 0;
    color: #002147;
    padding-bottom: 80px;
    text-align: center;
}

.hsis{
    color:#A51C30
}

.textWhite {
    color: #fff;
}


h4 {
    font-size: 20px;
    font-weight: bold;
    color: #fff;
    line-height: 18px;
}

h3 {
    font-size: 34px;
    color: #001C54;
    line-height: 1;
}

.h3_hsis{
    color:#A51C30
}

.row {
    display: flex;
    width: 100%;
}

.rewards_container {
    max-width: 1000px !important;
    margin: 0 auto;
}

.container {
    max-width: 1400px;
    margin: 0 auto;

}

* {
    box-sizing: border-box;
}

.container-full {
    max-width: 100%;
    line-height: 0;
}

.textUpper {
    text-transform: uppercase;
}

img {
    width: 100%;
}

.w-50 {
    overflow: hidden;
    width: 50% !important;
}

.mb-10 {
    margin-bottom: 10px !important;
}

.mb-20 {
    margin-bottom: 20px !important;
}

.mb-30 {
    margin-bottom: 30px !important;
}

.mt-10 {
    margin-top: 10px !important;
}

.mt-20 {
    margin-top: 20px !important;
}

.mt-30 {
    margin-top: 30px !important;
}

.pl-0 {
    padding-left: 0 !important;
}

.pr-0 {
    padding-right: 0 !important;
}

.pb-10 {
    padding-bottom: 10px !important;
}

.pb-20 {
    padding-bottom: 20px !important;
}

.pb-30 {
    padding-bottom: 30px !important;
}

.p-80-20 {
    padding: 80px 20px !important;
}

.pt-0 {
    padding-top: 0px !important;
}

.pt-10 {
    padding-top: 10px !important;
}

.pt-20 {
    padding-top: 20px !important;
}

.pt-30 {
    padding-top: 30px !important;
}

.fs-14 {
    font-size: 14px !important;
}

.fs-16 {
    font-size: 16px !important;
}

.fs-18 {
    font-size: 18px !important;
}

.fs-20 {
    font-size: 20px !important;
}

.fs-22 {
    font-size: 22px !important;
}

.fs-25 {
    font-size: 25px !important;
}

.fs-32 {
    font-size: 32px !important;
}

.fs-34 {
    font-size: 34px !important;
}

.fs-36 {
    font-size: 36px !important;
}

.fs-42 {
    font-size: 42px !important;
}

.fs-80 {
    font-size: 70px !important;
}

.fw-400 {
    font-weight: 400 !important;
}

.fw-500 {
    font-weight: 500 !important;
}

.fw-600 {
    font-weight: 600 !important;
}

.fw-700 {
    font-weight: 700 !important;
}

.lineHeight1 {
    line-height: 1;
}

.textOrg {
    color: #FB4F22;
}

.textBlack {
    color: #000 !important;
}

.textLOrg {
    color: #000000 !important;
}

.orgBg {
    background-color: #F9A21B !important;
}

.bgBlack {
    background-color: #000;
}

.bglightBlack {
    background-color: #212529;
}

.widthAuto {
    width: auto;
}




.menu_header_bg {
    display: none;
}






.mt-50{
    margin-top: 50px;
}




/* oxford mun */
.oxfordBanner {
    position: relative;
    max-height: 100vh;
    overflow: hidden;
}


/* .oxfordBanner::before {
    content: " ";
    background: linear-gradient(180deg, #382B28 0%, #382b2880 70%, #382c2900 100%);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 120px;
} */


/* video.sliderBgImage {
    width: 100%;
} */


.partnerSec.communityPartnerSec {
    padding-bottom: 80px;
}

.networkSchoolSlider-outer img {
    width: 70%;
    height: auto;
}

.networkSchoolSlider-outer .swiper {
    width: 95%;
}

/* 
.networkSchoolSlider{
    float: left;
    display: inline-block;
} */
/* .networkSchoolSlider-outer .swiper-css-mode > .swiper-wrapper > .swiper-slide {
    display: inline-block;
    float: left;
    width: auto;
} */

.oxfordBanner .banner-content h1 {
    font-size: 80px;
    font-weight: 600;
    line-height: 1;
    z-index: 2;
}

.oxfordBanner::after {
    content: " ";
    background: linear-gradient(180deg, #00000000 0%, #00000080 70%, #000000 100%);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 270px;
}

.oxfordBanner .banner-content .muteBtn {
    float: right;
    background-color: #fff;
    border-radius: 50px;
    margin-right: 30px;
    color: #002147;
    display: flex;
    align-items: center;
    line-height: 1;
    font-size: 16px;
    font-weight: 600;
    width: 40px;
    height: 40px;
    justify-content: center;
}

.oxfordBanner .banner-content .muteBtn img {
    width: auto;

}

.oxfordBanner .banner-content .mute img.unmuteIcon {
    display: block;
}

.oxfordBanner .banner-content .unmute img.unmuteIcon {
    display: none;
}

.oxfordBanner .banner-content .unmute img.muteIcon {
    display: block;
}

.oxfordBanner .banner-content .mute img.muteIcon {
    display: none;
}

.banner-content {
    width: 100%;
    text-align: center;
    position: absolute;
    bottom: 20px;
    color: #fff;
    z-index: 2;
}

.intro-section {
    background-color: #F5FCFF;
    padding: 80px 20px 80px 20px;
}

.introduction h2 {
    padding-bottom: 30px;
}

.introduction p {
    width: 850px;
    margin: 0 auto;
    text-align: center;
}

.textOrg {
    line-height: 1.2em;
}

video.sliderBgImage.past_movie_vid {
    width: 100%;
}

.video-caption p {
    padding: 20px;
}

.video-caption {
    display: flex;
    justify-content: space-between;
}

a.past_movie {
    border-radius: 50%;
    background-color: #fb4f22;
    /* height: 33px; */
    align-self: center;
    padding: 20px;
    margin-right: 20px;

}

button.registerBtn.registerChall {
    color: white !important;
}

.responsive-iframe-container {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    /* 16:9 aspect ratio (9/16 = 0.5625 or 56.25%) */
    overflow: hidden;
}

.responsive-iframe-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

.iframe_outer {
    height: 713px;
    width: 100%;
    position: absolute;
    top: 0;

}

.iframe-container {
    position: absolute;
    top: 0;
    left: 0;
    /* width: 650px;
    height: 350px; */
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 20;
}







.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 40;
    background-color: white;
    border: none;
    cursor: pointer;
    padding: 5px 10px;
}









img.arrow {
    width: 27px !important;
}

.orange_theme .faqSec .faqInnerLeft {
    position: relative;
    /* Ensure the overlay is positioned relative to this element */
    width: 45%;
    margin-right: 30px;
    border-radius: 10px;
    padding: 60px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("https://musite.b-cdn.net/videos/student_trained.jpeg") !important;
    display: inline-flex;
    align-items: center;
    color: white;
    /* Set the default text color to white */
}


.date_bold {
    color: white;
    font-weight: bold;
    font-size: 28px;

}



.orange_theme .faqSec .faqInnerLeft::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background-color: #F9A21B !important;
    opacity: 0.9;
    /* Adjust opacity as needed */
    z-index: 1;
    /* Ensure the overlay is behind the content */
}

.orange_theme .faqSec .faqInnerLeft-inner {
    position: relative;
    /* Ensure the content is above the overlay */
    z-index: 2;
    /* Place the content above the overlay */
}

.orange_theme .faqSec .faqInnerLeft-inner * {
    color: white;
    /* Ensure all child elements have white color */
}

.orange_theme .registerBtn {
    margin-bottom: 10px;
    color: #000000 !important;
}

.orange_theme .faqSec .faqInner {
    background-color: #FEF6E8 !important;
}

.orange_theme .registerBtn {
    background-color: #ffffff;
    padding: 15px 35px;
    color: #F9A21B;
}

/* .orange_theme .communityPartnerSec .swiper-pagination .swiper-pagination-bullet-active:before, .experienceSec .swiper-pagination .swiper-pagination-bullet-active:after {
        border: 1px solid #F9A21B !important;
    } */
.orange_theme .communityPartnerSec .swiper-pagination .swiper-pagination-bullet-active,
.experienceSec .swiper-pagination .swiper-pagination-bullet-active {
    background: #F9A21B !important;
}

/* Green Theme Page YLB */



.green_theme .callToAction .callToAction-inner,
.green_theme .joinSec,
.green_theme .whyOxfordSec,
.green_theme .conference .confere-reg {
    background-color: #235347;
    background-image: url(../../../public/assets/images/bg_design.png) !important;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

.green_theme .venueSpotSec .venueSpot-inner .venueSpotContent {
    border-color: #235347 !important;
}

.green_theme .whyOxfordSec {
    padding: 150px 20px !important;
}

.green_theme .callToAction .callToAction-inner {
    padding: 80px 100px;
}

.green_theme .venueSpotSec .venueSpot-inner .venueSpotContent h2 {
    color: black;
}

.green_theme .oxford-mun .secTitle {
    color: #fff !important;
}


.green_theme .secTitle,
.eventTitle,
.green_theme .oxford-box p,
.green_theme .oxford-box h3 {
    color: black !important;
}

.green_theme .secTitle {
    color: black;
}

.green_theme .confere-box,
.green_theme .conference .confere-detail .confere-box:before {
    border-color: #235347;
}

.green_theme .conference .confere-detail .confere-box:before {
    background-color: #235347 !important;
}

.green_theme .registerBtn {
    background-color: #ffffff;
    padding: 15px 35px;
    color: #235347;
}


.green_theme .faqSec .faqInnerLeft {
    position: relative;
    /* Ensure the overlay is positioned relative to this element */
    width: 45%;
    margin-right: 30px;
    border-radius: 10px;
    padding: 60px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("https://musite.b-cdn.net/videos/student_trained.jpeg");
    display: inline-flex;
    align-items: center;
    color: white;
    /* Set the default text color to white */
}

.green_theme .faqSec .faqInnerLeft::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background-color: #235347;
    opacity: 0.9;
    /* Adjust opacity as needed */
    z-index: 1;
    /* Ensure the overlay is behind the content */
}

.green_theme .faqSec .faqInnerLeft-inner {
    position: relative;
    /* Ensure the content is above the overlay */
    z-index: 2;
    /* Place the content above the overlay */
}

.green_theme .faqSec .faqInnerLeft-inner * {
    color: white;
    /* Ensure all child elements have white color */
}

.green_theme .registerBtn,
.confere-box h3 {
    color: #235347 !important;
}

.green_theme .conference .confere-box span.bgColorText {
    background-color: #235347 !important;
}

.green_theme .confere-box h3 {
    color: black !important;
}

.green_theme .conference .confere-reg h3,
.green_theme .conference .confere-reg p {
    color: #fff;
}

.green_theme .conference .confere-reg p span {
    background-color: #ffffff;
    color: #000000;
}

.green_theme .faqSec .faqInner {
    background-color: #E9EEED !important;
}


.green_theme .communityPartnerSec .swiper-pagination .swiper-pagination-bullet-active:before,
.experienceSec .swiper-pagination .swiper-pagination-bullet-active:after {
    border: 1px solid #235347;
}

.green_theme .communityPartnerSec .swiper-pagination .swiper-pagination-bullet-active,
.experienceSec .swiper-pagination .swiper-pagination-bullet-active {
    background: #235347 !important;
}


/* END */

/*Red Theme Page LAB */
.red_theme .callToAction .callToAction-inner,
.red_theme .joinSec,
.red_theme .whyOxfordSec,
.red_theme .conference .confere-reg {
    background-color: #E74C3C;
    background-image: url(../../../public/assets/images/bg_design.png);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

.red_theme .conference .confere-reg p {
    color: #fff;
}

.red_theme .conference .confere-reg h3 {
    color: #fff;
}

.red_theme .venueSpotSec .venueSpot-inner .venueSpotContent {
    border-color: #E74C3C !important;
}

.red_theme .whyOxfordSec {
    padding: 150px 20px !important;
}

.red_theme .callToAction .callToAction-inner {
    padding: 80px 100px;
}

.red_theme .secTitle,
.red_theme .oxford-box p,
.red_theme .oxford-box h3 {
    color: #000;
}

.red_theme .confere-box,
.red_theme .conference .confere-detail .confere-box:before {
    border-color: #E74C3C;

}

.red_theme .registerBtn {
    margin-bottom: 20px;
    background-color: #ffffff;
    padding: 15px 35px;
    color: #E74C3C !important;
}

.red_theme .faqSec .faqInnerLeft {
    position: relative;
    /* Ensure the overlay is positioned relative to this element */
    width: 45%;
    margin-right: 30px;
    border-radius: 10px;
    padding: 60px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("https://musite.b-cdn.net/videos/student_trained.jpeg");
    display: inline-flex;
    align-items: center;
    color: white;
    /* Set the default text color to white */
}

.red_theme .faqSec .faqInnerLeft::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background-color: #E74C3C;
    opacity: 0.9;
    /* Adjust opacity as needed */
    z-index: 1;
    /* Ensure the overlay is behind the content */
}

.red_theme .faqSec .faqInnerLeft-inner {
    position: relative;
    /* Ensure the content is above the overlay */
    z-index: 2;
    /* Place the content above the overlay */
}

.red_theme .faqSec .faqInnerLeft-inner * {
    color: white;
    /* Ensure all child elements have white color */
}

.red_theme .faqSec .faqInner {
    background-color: #FDEDEB !important;
}

.red_theme .conference .confere-detail .confere-box:before {
    background-color: #E74C3C !important;
}

.red_theme .conference .confere-reg p span {
    background-color: #ffffff;
    color: #000000;
}

.red_theme .venueSpotSec .venueSpot-inner .venueSpotContent h2 {
    color: #000 !important;
}

.red_theme .communityPartnerSec .swiper-pagination .swiper-pagination-bullet-active:before,
.experienceSec .swiper-pagination .swiper-pagination-bullet-active:after {
    border: 1px solid #E74C3C;
}

.red_theme .communityPartnerSec .swiper-pagination .swiper-pagination-bullet-active,
.experienceSec .swiper-pagination .swiper-pagination-bullet-active {
    background: #E74C3C !important;
}

/* END */










/*Multy Theme Page MU20HighschoolSummit24 */


.red_bg{
    background-color: #A51C30!important;
}


.red_bg p span {
    background-color: #fff !important;
    color: #A51C30 !important;
}

.red_bg a.registerBtn {
    background-color: #fff;
    color: #A51C30 !important;
}

html body .red_color .confere-box {
    border-color: #A51C30 !important;
}



html body .red_color .confere-box:before{
    background-color: #A51C30;
}

#whyOxfordsec {
    background-color: #A51C30!important;
    background-image: url(http://localhost:3000/static/media/bg_design.0d7b001….png);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

.registration_box {
    background-color: #1C2630 !important;
}

.gray_theme .registerBtn {
    background-color: #fff!important;
    padding: 15px 35px;
    color: #A51C30 !important;
}

.gray_theme .conference .confere-detail .confere-box:before {
    background-color: #A51C30 !important;
}

.gray_theme .confere-box,
.gray_theme .conference .confere-detail .confere-box:before {
    border-color: #A51C30 !important;

}

.gray_theme .conference .confere-box span.bgColorText {
    background-color: #A51C30 !important;
}




.joinSec {
    background-color: #F9A31C;
}

.movement_box {
    padding: 100px 0px;
    background-color: #FF9A00;
}


.movement_box .row {
    padding: 100px 0px !important;
}

.white_btn {
    background-color: #fff;
    padding: 15px 35px;
    border-radius: 50px;
    color: #000 !important;
    text-decoration: none;
    font-weight: bold;
}



.column.reportimpact-img {
    text-align: center;
    align-content: center;
    /* border: 5px solid #072654; */
}


.callToAction-inner {
    background-color: #1C2630;
}

.gray_theme .faqSec .faqInnerLeft::before {
    background-color: #A51C30!important;
}



/* #testimonialhomeSec {
    background-color: #A51C30!important;
} */


h2.Testimonial_ {
    font-size: 70px;
    line-height: 1;
    margin: 0;

    padding-bottom: 80px;
    text-align: center;
    color: #fff !important;
}






.gray_theme .intro-section {

    padding: 80px 20px 80px 20px;
}

.gray_theme .callToAction .callToAction-inner,
.gray_theme .joinSec,
.gray_theme .whyOxfordSec,
.gray_theme .conference .confere-reg {

    background-image: url(../../../public/assets/images/bg_design.png);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

.gray_theme .venueSpotSec .venueSpot-inner .venueSpotContent {
    border-color: #A51C30 !important;
}

.gray_theme .whyOxfordSec {
    padding: 150px 20px !important;
}

.gray_theme .callToAction .callToAction-inner {
    padding: 80px 100px;
}

.gray_theme .secTitle,
.gray_theme .oxford-box p,
.gray_theme .oxford-box h3 {
    color: #000;
}

.gray_theme .venueSpotSec .venueSpot-inner .venueSpotContent h2,
.reviewTitle {
    color: #000;
}


.gray_theme .conference .confere-detail .confere-box:before {
    background-color: #989898;
}

.gray_theme .conference .confere-reg p {
    color: #fff;
}

.gray_theme .conference .confere-reg h3 {
    color: #fff;
}




.gray_theme .faqSec .faqInnerLeft {
    position: relative;
    /* Ensure the overlay is positioned relative to this element */
    width: 45%;
    margin-right: 30px;
    border-radius: 10px;
    padding: 60px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('https://musite.b-cdn.net/videos/student_trained.jpeg');
    display: inline-flex;
    align-items: center;
    color: white;
    /* Set the default text color to white */
}

.gray_theme .faqSec .faqInnerLeft::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;

    opacity: 0.9;
    /* Adjust opacity as needed */
    z-index: 1;
    /* Ensure the overlay is behind the content */
}

.gray_theme .faqSec .faqInnerLeft-inner {
    position: relative;
    /* Ensure the content is above the overlay */
    z-index: 2;
    /* Place the content above the overlay */
}

.gray_theme .faqSec .faqInnerLeft-inner * {
    color: white;
    /* Ensure all child elements have white color */
}


.gray_theme .registerBtn {
    margin-bottom: 10px;

}

.gray_theme .faqSec .faqInner {
    background-color: #F0F0F0 !important;
}


.gray_theme .communityPartnerSec .swiper-pagination .swiper-pagination-bullet-active:before,
.experienceSec .swiper-pagination .swiper-pagination-bullet-active:after {
    border: 1px solid #989898;
}

.gray_theme .communityPartnerSec .swiper-pagination .swiper-pagination-bullet-active,
.experienceSec .swiper-pagination .swiper-pagination-bullet-active {
    background: #989898 !important;
}

/* END */




/* Blue Theme Page MU20HighschoolSummit24 */


.blue_theme .communityPartnerSec .swiper-pagination .swiper-pagination-bullet-active:before,
.experienceSec .swiper-pagination .swiper-pagination-bullet-active:after {
    border: 1px solid #072654 !important;
}

.blue_theme .communityPartnerSec .swiper-pagination .swiper-pagination-bullet-active,
.experienceSec .swiper-pagination .swiper-pagination-bullet-active {
    background: #072654 !important;
}

.blue_theme .intro-section {
    background-color: rgba(152, 152, 152, 0.07);
    padding: 80px 20px;
}

.blue_theme .callToAction .callToAction-inner,
.blue_theme .joinSec,
.blue_theme .communityWhyOxfordSec,
.blue_theme .conference .confere-reg {
    background: url(../../../public/assets/images/blue-bg.png) no-repeat center center / cover !important;
}

.blue_theme h5.textBlack.fw-700.priceHeading {
    color: white !important;
}

.blue_theme .venueSpotSec .venueSpot-inner .venueSpotContent h2,
.blue_theme .oxford-box p,
.blue_theme .oxford-box h3,
.blue_theme .communityConference .confere-reg p span,
.blue_theme .textLOrg,
.blue_theme {
    color: black !important;
}

.blue_theme .callToAction .callToAction-inner {
    padding: 80px 100px !important;
}

.blue_theme .venueSpotSec .venueSpot-inner .venueSpotContent,
.blue_theme .confere-box,
.blue_theme .conference .confere-detail .confere-box:before,
.blue_theme .communityFlameUni .communityFlameUni-outer .communityFlameUni-innerRight:before,
.blue_theme .experienceSec .experienceBox,
.blue_theme .rewardSec .innnerContent {
    border-color: #072654 !important;
}

.blue_theme .registerBtn,
.confere-box h3 {
    color: black !important;
}

.bold {
    font-weight: bold;
    color: #000;
}

.blue_theme .communityConference .confere-detail .confere-box:before {
    background-color: #072654 !important;
}

.blue_theme .registerBtn {
    margin-bottom: 10px;
    background-color: #ffffff;
    padding: 15px 35px;
    color: #072654 !important;
}

.blue_theme .experienceSec .swiper-slide-active .experienceBox,
.blue_theme .experienceSec .experienceBox-inner img.nonActive,
.blue_theme .rewardSec .priceHeading {
    background-color: #072654 !important;
}

.blue_theme .faqSec .faqInnerLeft {
    position: relative;
    /* Ensure the overlay is positioned relative to this element */
    width: 45%;
    margin-right: 30px;
    border-radius: 10px;
    padding: 60px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("https://musite.b-cdn.net/videos/student_trained.jpeg") !important;
    display: inline-flex;
    align-items: center;
    color: white;
    /* Set the default text color to white */
}

.blue_theme .faqSec .faqInnerLeft::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background-color: #072654 !important;
    opacity: 0.9;
    /* Adjust opacity as needed */
    z-index: 1;
    /* Ensure the overlay is behind the content */
}

.blue_theme .faqSec .faqInnerLeft-inner {
    position: relative;
    /* Ensure the content is above the overlay */
    z-index: 2;
    /* Place the content above the overlay */
}

.blue_theme .faqSec .faqInnerLeft-inner * {
    color: white;
    /* Ensure all child elements have white color */
}




/* END */














/* Why Oxford MUN India ? */

.whyOxfordSec {
    background-image: url(../../../public/assets/images/blue-bg.png);
    background-repeat: no-repeat;
    padding: 150px 20px 150px 20px !important;
    background-position: center center;
    background-size: cover;
}

.eventSecHsis{
    background-color: #A51C30;
    background-image: url(../../../public/assets/images/bg_design.png) !important;
}

.whyOxfordSec .inner-box {
    border-radius: 50px;
    padding: 50px;

    background-color: #fff;
}

.whyOxfordSec .row {
    gap: 120px;
}

.column {
    width: 50%;
}

.whyOxfordSec .oxford-mun h2 {
    color: #fff;


}

.whyOxfordSec .oxford-box h3 {
    width: 380px;
    padding-bottom: 20px;
    line-height: 1.2;

}

.whyOxfordSec .column .oxford-box p {
    padding-right: 50px;
}

.whyOxfordSec .column .oxford-box:first-child {
    padding-bottom: 40px;
    border-image: linear-gradient(to left, #fff, #66666680, #666666cc, #666666) 1;
    border-bottom: 2px solid;
    margin-bottom: 40px;
}

.introduction {
    padding: 0px 20px;
}

.joinSec .row {
    padding: 20px;
}


/* Conference Details */
.confere-detail {
    padding: 0px 90px;
}

.conference {
    padding: 100px 20px;
}

.confere-box {
    border: 3px solid #002147;
    border-radius: 22px;
    padding: 55px 45px;
    margin-bottom: 40px;
    position: relative;
}

.confere-box_hsis{
    border: 3px solid #a51c31;
}

.confere-box h3 {
    padding-bottom: 25px;
    text-transform: uppercase;
}

.conference .confere-box p {
    color: #4f4f4f;
    font-weight: 500;
    padding-bottom: 5px;
}

.conference .confere-box span.bgColorText {
    background-color: #072654;
    font-size: 18px;
    line-height: 2;
    padding: 3px 10px;
    font-weight: 600;
    color: #fff;
    font-style: italic;
}

.conference .confere-box span.bgColorText_hsis{
    background-color:#A51C30
}

.conference .confere-detail .confere-box:before {
    content: " ";
    position: absolute;
    right: 25px;
    top: 0px;
    width: 16px;
    height: 100%;
    background-color: #002147;
}

.conference .confere-detail .confere-box_hsis:before{
    background-color:#A51C30
}
.conference .confere-detail .row {
    gap: 40px;
}

.conference .confere-reg {
    background-color: #002147;
    border-radius: 22px;
    padding: 30px;
    display: inline-flex;
    align-items: center;
}

.conference .confere-reg-hsis{
    background-color:#A51C30
}

.conference .confere-reg p {
    color: #DDF5FF;
    padding-bottom: 45px;
    line-height: 1.6;
    font-weight: 500;
}
.conference .confere-reg-hsis p{
    color:#F4CDD3
}


.conference .confere-reg .header-btn {
    margin-left: 0px;
}

.conference .confere-reg h3 {
    color: #DDF5FF;
    padding-bottom: 20px;
    text-transform: uppercase;
}
.conference .confere-reg-hsis h3{
    color:#F4CDD3
}


.conference .confere-reg p span {
    background-color: #ddf5ff;
    color: #002147;
    padding: 0 3px;
    font-weight: 600;
    font-style: italic;
}

.conference .confere-reg-hsis p span{
    color:#A51C30;
    background-color:#F4CDD3;
}



.exploreBtn {
    width: 214px !important;
    margin-right: 20px;
}

.registerBtn.joinbtn {
    background-color: #000;
    margin-left: 10px;
}

.registerBtn {

    cursor: pointer;
    background-color: #FB4F22;
    padding: 15px 35px;
    color: #fff;
    border-radius: 50px;
    font-size: 18px;
    font-weight: 600;
    border: none;
    text-decoration: none;
    line-height: 1;
    display: inline-block;
}

.Oxfordmunprechallenge_reg p {
    padding-bottom: 20px !important;
}

.eventSec {
    padding: 100px 20px;
}

.eventSec .row {
    gap: 60px;
}

.eventSec .eventBox {
    background-color: #fff;
    border-radius: 20px;
    width: auto;
    margin: 0 auto;
}

.eventSec .eventBox .eventContentBox {
    padding: 40px 25px 40px;
    position: relative;
    min-height: 294px !important;
}

/* Individual tab items */
.react-tabs__tab {
    display: inline-block;
    color: #555;
    background-color: #f0f0f0;
    padding: 12px 20px;
    margin-bottom: -1px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border: 1px solid #ccc;
    border-bottom: 0;
    /* No bottom border */
}

/* Selected (active) tab style */
.react-tabs__tab--selected {
    background-color: #a51c302e ;
    /* Background color for selected tab */
    color: #A51C30!important ;
    /* Darker text color for selected tab */
    position: relative;
    z-index: 1;
    /* Ensure selected tab appears above others */
    border: 1px solid #148188;
    /* Border matching the selected tab color */
    border-bottom: 0 !important;
    /* No bottom border */
}

/* Container for the tab list */
ul.react-tabs__tab-list {
    display: flex;
    justify-content: space-around;
    padding: 0;
    margin: 0;
    list-style-type: none;
    border-bottom: 1px solid #ccc;
    /* Bottom border for tab list */
}

/* Additional styles for specific tabs */
.ehis,
.nmims {
    cursor: pointer;
    color: #8080809c;
    border-radius: 15px 15px 0px 0px;
    border: 1px solid #8080809c;
    /* Solid black border */
    font-size: 18px;
    font-weight: 600;
    padding: 10px 30px;
    border-bottom: 0;
    /* Remove bottom border */
    transition: all 0.5s ease;
}

.ehis {
    margin-right: 10px;
}

.nmims {
    margin-left: 10px;
}

/* Focused tab style */
.react-tabs__tab-list li:focus {
    outline: none;
    box-shadow: none;
}

.eventSec .eventBox img {
    width: 100%;
    border-radius: 20px 20px 0 0;
    height: 280px;
    object-fit: cover;
}

.eventSec .eventBox .eventTitle {
    font-size: 24px;
    font-weight: 700;
    color: #002147;
    line-height: 1;
    padding-bottom: 10px;
}

.eventSec .eventBox p {
    color: #4F4F4F;
    font-weight: 500;
}

.eventSec .eventBox .eventDate {
    font-size: 28px;
    font-weight: 700;
    line-height: 1;
    display: inline-block;
    text-align: center;
    position: absolute;
    top: -50px;
    background-color: #002147;
    color: #fff;
    padding: 15px;
    border-radius: 6px;
    border: 2px solid #fff;
}

.eventSec .eventBox .eventDate span {
    display: block;
    font-size: 16px;
    font-weight: 400;
    padding-top: 10px;
}


/* .partnerHomeSec .networkSchoolSlider-outer:before{
    content: " ";
    position: absolute;
    left: -100px;
    top: calc(50% - 20px);
    width: 45px;
    height: 125px;
    transform: translateY(-50%);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    background-image: url('http://localhost:3000/static/media/gehuIconLeft.3922e7ab3ed419d679b0.svg');
}

.partnerHomeSec .networkSchoolSlider-outer:after{
    content: " ";
    position: absolute;
    right: -100px;
    top: calc(50% - 20px);
    width: 45px;
    height: 125px;
    transform: translateY(-50%);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    background-image: url('http://localhost:3000/static/media/gehuIconRight.865689471d9ea79c9817.svg');
}
 */



.partnersecSlider-outer img {
    width: auto;
}

/* 
.networkSchoolSlider{
    float: left;
    display: inline-block;
} */
/* .partnersecSlider-outer .swiper-css-mode > .swiper-wrapper > .swiper-slide {
    display: inline-block;
    float: left;
    width: auto;
} */







.partnerSec .topHeading p {
    font-size: 16px;
    font-weight: 400;
    color: #d1d1d1;
    width: 1000px;
    margin: 0 auto;
}


.partnerSec .networkSchoolSlider-outer {
    /* display: grid;
    gap: 20px;
    grid-template-columns: repeat(4, 1fr);
    justify-content: center;
    align-items: center; */
    width: 85%;
    margin: 0 auto;
    position: relative;
}

/* .networkSchoolSec .networkSchoolSlider-outer .swiper-slide {
    width: auto !important;
} */

/* .networkSchoolSec .networkSchoolSlider img {
    width: auto;
} */

.partnerSec .swiper {
    padding-bottom: 50px;
}

.mentors_logos {
    display: flex;
    justify-content: center;
}

.mentors_logos .partnerLogo {
    margin: 0px 40px;
}

/* .partnerSec {
    padding: 100px 0px;
    overflow: hidden;
}

.partnerSec .partnerLogoGrid {
    display: grid;
    gap: 25px;
    grid-template-columns: repeat(9, 1fr);
}

.partnerSec .partnerLogoGrid .partnerLogo {
    display: inline-flex;
    justify-content: center;
}

.partnerSec .partnerLogoGrid .partnerLogo img {
    width: auto !important;
} */






.venueSpotSec {
    padding: 50px 20px 50px;
}

.venueSpotSec .venueSpot-inner {
    padding: 40px 0px;
    display: flex;
    align-items: center;
}

.venueSpotSec .venueSpot-inner .venueSpotContent h2 {
    font-size: 42px;
    font-weight: 600;
    line-height: 1;
    padding-bottom: 30px;
    color: #072654;
}
.venueSpotSec .venueSpot-inner .venueSpotContentHsis h2 {
    
    color: #A51C30;
}

.venueSpotSec .venueSpot-inner .venueSpotContent {
    padding: 60px 70px 60px 140px;
    border: 5px solid #072654;
    margin-left: -70px;
    position: relative;
    z-index: 2;
}
.venueSpotSec .venueSpot-inner .venueSpotContentHsis {
    
    border: 5px solid #A51C30;
    
}

.venueSpotSec .venueSpot-inner .image-gallery-slides {
    border-radius: 30px;
}

.venueSpotSec .venueSpot-inner img {
    width: 770px;
    object-fit: cover;
}

.venueSpotSec .venueSpot-inner .image-gallery-thumbnail .image-gallery-thumbnail-image {
    width: 100% !important;
    border-radius: 10px;
}

.venueSpotSec .venueSpot-inner .image-gallery-thumbnail.active .image-gallery-thumbnail-image {
    border: 2px solid #FFF;
}

.venueSpotSec .venueSpot-inner .image-gallery-thumbnail {
    width: 150px;
    margin: 0px 10px;
    border: none;

}

.intreactionInner {
    position: relative;
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent black overlay */
    padding: 20px;
    /* Adjust padding as needed */
}

.intreactionInner h2,
.intreactionInner p {
    position: relative;
    z-index: 1;
    /* Ensure text stays above the overlay */
}

.intreactionInner::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent black background */
    z-index: 0;
    /* Place it behind the text */
}







.venueSpotSec .venueSpot-inner .image-gallery-thumbnails-wrapper {
    position: absolute;
    bottom: 10px;
    left: 20px;
    overflow-x: scroll;
}

.venueSpotSec .venueSpot-inner .image-gallery-content {
    overflow: hidden;
}

.venueSpotSec .venueSpot-inner .image-gallery-thumbnails-wrapper::-webkit-scrollbar {
    display: none;
}

.venueSpotSec .venueSpot-inner .image-gallery-left-nav,
.image-gallery-right-nav {
    padding: 0px;
    top: 50%;
    transform: translateY(-50%);
    background-color: #ffffff80;
    border-radius: 50px;
    width: 40px;
    height: 40px;
}

.venueSpotSec .venueSpot-inner .image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
    height: 30px;
    width: 30px;
    color: #072654;
}

.venueSpotSec .venueSpot-inner .image-gallery-right-nav {
    right: 15px;
}

.venueSpotSec .venueSpot-inner .image-gallery-left-nav {
    left: 15px;
}

.testimonialSec {
    padding: 50px 20px 100px;
    overflow-x: hidden;
}

.testimonialSec .testimonial-inner {
    display: grid;
    gap: 50px;
    grid-template-columns: repeat(5, 1fr);
}

.testimonialSec .testimonialImg img {
    width: 100%;
}

.testimonialSec .testimonialImg .textWrapper img {
    width: 40px;
    display: block;
    padding-bottom: 25px;
}

.testimonialSec .testimonialImg {
    position: relative;
    line-height: 0;
}

.testimonialSec .testimonialImg:before {
    content: " ";
    position: absolute;
    background-color: #072654;
    height: 65px;
    width: 65px;
    bottom: -10px;
    left: -10px;
    z-index: -1;
    transition: all 0.5s ease-in-out;
    opacity: 0;
    visibility: hidden;
}

.testimonialSec .testimonialImg:after {
    content: " ";
    position: absolute;
    top: -8px;
    right: -10px;
    width: 110px;
    height: 64px;
    z-index: -1;
    transition: all 0.5s ease-in-out;
    background-image: url(../../../public/assets/images/testlBgShape.svg);
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    opacity: 0;
    visibility: hidden;
}

.testimonialSec .testimonialImg:hover::before,
.testimonialSec .testimonialImg:hover::after {
    opacity: 1;
    visibility: visible;
}

.testimonialSec .testimonialImg .textWrapper {
    position: absolute;
    bottom: 40px;
    left: 50%;
    width: 100%;
    z-index: 2;
    text-align: -webkit-center;
    transform: translate(-50%);
    transition: all 0.5s ease-in-out;

}

.testimonialSec .testimonialImg .textWrapper {
    font-size: 32px;
    font-weight: 700;
    color: #fff;
    opacity: 1;
    visibility: visible;

}

.testimonialSec .testimonialImg:hover .textWrapper {
    opacity: 0;
    visibility: hidden;
}

.testimonialSec .testimonialImg:hover .textWrapper:before {
    height: 100px;
}

.testimonialSec .testimonialImg .textWrapper:before {
    content: " ";
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 0px;
    z-index: -1;
    transition: all 0.5s ease-in-out;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 8%, rgba(0, 0, 0, 0.6) 100%);
}

.reviewSec {
    padding: 100px 0px 0px;
    position: relative;
}


.contactPopup .contactForm{

    padding: 30px;
        max-height: 80vh !important;
        overflow: auto;
}

.reviewSec .reviewContent {
    background-color: #fff;
    border-radius: 80px;
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translate(-50%);
    padding: 30px 50px;
    width: 610px;
    /* width: 560px; */
}

.reviewSec .reviewContent .reviewTitle {
    text-align: center;
    font-weight: 700;
}

.reviewSec .reviewContent p {
    font-size: 16px;
    text-align: center;
    padding: 0 0px;
}

.reviewSec .reviewContent p span {
    display: block;
    font-size: 22px;
    font-weight: 600;
}

/* .reviewSec .reviewContent:before {
    content: " ";
    position: absolute;
    left: 30px;
    top: 50%;
    width: 14px;
    height: 20px;
    transform: translateY(-50%);
    background-image: url("../../../public/assets/images/leftArrow.svg");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
}

.reviewSec .reviewContent::after {
    content: " ";
    position: absolute;
    right: 30px;
    top: 50%;
    width: 14px;
    height: 20px;
    transform: translateY(-50%);
    background-image: url("../../../public/assets/images/rightArrow.svg");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
} */


.source_field {
    display: none;
}


.reviewSec .swiper-slide img {
    /* object-fit: cover !important; */
    /* width: 100% !important; */
    /* height: 800px !important; */
    object-position: bottom;
}

.reviewSec .swiper-button-prev:after {
    content: " ";
    position: absolute;
    left: 0;
    top: -20px;
    width: 14px;
    height: 20px;
    background-image: url("../../../public/assets/images/leftArrow.svg");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
}

.reviewSec .swiper-button-prev {
    position: absolute;
    left: calc(50% - 280px);
    margin: 0px;
    top: initial;
    bottom: 65px;
    width: 14px;
    height: 20px;
}

.reviewSec .swiper-button-next::after {
    content: " ";
    position: absolute;
    left: 0;
    top: -20px;
    width: 14px;
    height: 20px;
    background-image: url("../../../public/assets/images/rightArrow.svg");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
}

.reviewSec .swiper-button-next {
    position: absolute;
    right: calc(50% - 280px);
    margin: 0px;
    top: initial;
    bottom: 65px;
    width: 14px;
    height: 20px;
}



.joinSec {
    padding: 20px 20px;
    background-image: url("../../../public/assets/images/bluebg.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.joinSecHsis {
    
    background-image: url("../../../public/assets/images/hsis/1920-576.png");
    
}

.mun_logo img {
    width: 55% !important;
}

.joinSec .logoImage img {
    width: 90%;
}

.reportimpact-img img {
    width: 558px;
}

.joinSec .reportimpact-img {
    text-align: right !important;
}

.joinSec .impact-report-box {
    padding: 100px 0px !important;
}

.joinSec .logoImage {
    text-align: left !important;
    text-align: -webkit-center;
}

.joinSec .content-wrapperCol {
    display: flex;
    align-items: center;
}

.joinSec .content-wrapperCol .content-wrapper .contentTitle {
    color: #fff;
    font-size: 42px;
    font-weight: 700;
    line-height: 1.3;
    padding-bottom: 10px;
    margin-bottom: 30px;
    position: relative;
}

.joinSec .content-wrapperCol .content-wrapper .contentTitle:after {
    content: " ";
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 230px;
    height: 5px;
    background-color: #fff;
    border-radius: 30px;
}

.joinSec .content-wrapperCol .content-wrapper p {
    color: #fff;
    font-weight: 500;
    line-height: 1.6;
    padding-bottom: 30px;
}

/* .joinSec .content-wrapperCol .content-wrapper .registerBtn {
    background-color: #fff;
    color: #002147;
} */

.callToAction {
    padding: 100px 20px;
}

.callToAction .callToAction-inner {
    padding: 80px 100px;
    background-image: url("../../../public/assets/images/readyBg.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    border-radius: 50px;

}

.callToAction .callToAction-inner h5 {
    color: #fff;
    font-size: 42px;
    font-weight: 700;
    padding-bottom: 15px;
}

.callToAction .callToAction-inner p {
    color: #fff;
    font-weight: 500;
    line-height: 1.6;
}

.callToAction .callToAction-inner .callToActionLeft p {
    width: 300px;
    padding: 0;
}

.callToAction .callToAction-inner .callToActionRight p {
    width: 100%;
    padding-bottom: 60px;
}

.faqSec {
    padding: 0px 20px 100px;
}


.faqSec .faqInner {
    padding: 80px;
    background: #F5FCFF;
    border-radius: 22px;
}

.faqSec .faqInnerLeft {
    width: 45%;
    margin-right: 30px;
    border-radius: 10px;
    padding: 60px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: 
        linear-gradient(rgba(0, 33, 71, 0.9), rgba(0, 33, 71, 0.9)), 
        url("https://musite.b-cdn.net/videos/student_trained.jpeg");
    display: inline-flex;
    align-items: center;
}

.faqSec .faqInnerLeftHsis {
    width: 45%;
    margin-right: 30px;
    border-radius: 10px;
    padding: 60px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: 
        linear-gradient(rgba(165, 28, 48, 0.9), rgba(165, 28, 48, 0.9)), 
        url("https://musite.b-cdn.net/videos/student_trained.jpeg");
    display: inline-flex;
    align-items: center;
}


.faqSec .faqInnerRight {
    width: 55%;
    padding-left: 30px;
}

.faqSec .faqInnerRight .secTitle {
    text-align: left;
    padding-bottom: 30px;
}

.faqSec .faqInnerLeft img {
    width: 170px;
    padding-bottom: 15px;
}

.faqSec .faqInner p {
    color: #fff;
    font-weight: 500;
    padding-bottom: 50px;
    line-height: 1.6;
}

.faqSec .faqInnerRight .faqBlock .accordion {
    border: none;
    border-radius: 0;
}

.faqSec .faqInnerRight .faqBlock .accordion .accordion__button {
    background: transparent;
    color: #000;
    padding: 40px 20px;
    position: relative;
    box-sizing: border-box;
}

.faqSec .faqInnerRight .faqBlock .accordion .accordion__item {
    border-bottom: 1px solid #00000080;
    border-top: none;
}

.faqSec .faqInnerRight .faqBlock .accordion .accordion__button .faqTitle {
    font-size: 16px;
    font-weight: 500;
    color: #000;
    display: inline-flex;
    align-items: center;
}

.faqSec .faqInnerRight .faqBlock .accordion .accordion__button .faqTitle img {
    width: auto;
    margin-right: 15px;
}

.faqSec .faqInnerRight .faqBlock .accordion .accordion__button:before {
    position: absolute;
    left: initial;
    right: 0px;
    width: 15px;
    height: 15px;
    background-image: url("../../../public/assets/images/plus.svg");
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    transform: rotate(0);
    border: none;
    margin: 0;
}

.faqSec .faqInnerRight .faqBlock .accordion .accordion__panel p {
    color: #000;
    font-size: 16px;
    font-weight: 400;
    padding: 0px;
}

.faqSec .faqInnerRight .faqBlock .accordion .accordion__panel p>a {
    color: #fb4f22;
}

.faqSec .faqInnerRight .faqBlock .accordion .accordion__panel {
    padding: 0px 50px 30px;
}

.faqSec .faqInnerRight .faqBlock .accordion .accordion__button[aria-expanded='true']::before,
.faqSec .faqInnerRight .faqBlock .accordion .accordion__button[aria-selected='true']::before {
    background-image: url("../../../public/assets/images/minus.svg");
}

.faqSec .faqInnerRight .faqBlock .accordion .accordion__panel {
    max-height: 200px;
    padding: 0px 50px 30px;
    opacity: 1;
    transition: all 1s linear;
}

.faqSec .faqInnerRight .faqBlock .accordion .accordion__panel[hidden] {
    max-height: 0px;
    overflow: hidden;
    transition: all 1s linear;
    display: block;
    opacity: 0;
    padding: 0px 50px 0px;
}

.faqSec .faqInnerLeft .contactDetail img {
    width: auto;
    display: inline-block;
    padding: 0px 20px 0px 0px;
}

.faqSec .faqInnerLeft .contactDetail a {
    display: inline-flex;
    width: 100%;
    align-items: center;
    color: #fff;
    padding-top: 20px;
}

.faqSec .faqInnerLeft .socialLinks img {
    width: 30px;
    padding: 0px;
}

.faqSec .faqInnerLeft .socialLinks {
    padding-top: 20px;
}

.faqSec .faqInnerLeft .socialLinks a+a {
    margin-left: 20px;
}

.faqSec .faqInnerLeft a.registerBtn {
    margin-bottom: 30px;
}

/* ------------------------------------------------------------------------------------------------------- */

.topHeading {
    text-align: center;
    padding-bottom: 50px;
    /* padding-top: 50px; */
}

.topHeading h6,
.topHeading h2 {
    line-height: 1;
}

.bgOrg {
    background-color: #FB4F22;
}

.bgYellow {
    background-color: #FFBC00;
}

.bgSkyBlue {
    background-color: #2EBBDF;
}

.bgDarkRed {
    background-color: #A21B43;
}

.ourPillarsSec .ourPillars-outer {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(4, 1fr);
}

.ourPillarsSec .ourPillars-outer .pillars {
    position: relative;
}

.ourPillarsSec .ourPillars-outer .pillars .bgPillarsImg {
    width: 100%;
    height: 72vh;
    /* height: 100%; */
    object-fit: cover;
}

.ourPillarsSec .ourPillars-outer .titleTop {
    position: absolute;
    top: 30px;
    left: 31px;
}

.ourPillarsSec .ourPillars-outer .titleTop h4 {
    display: inline-block;
    padding: 5px 10px;
    margin-bottom: 20px;
}

.ourPillarsSec .ourPillars-outer .content {
    position: absolute;
    bottom: 30px;
    left: 0px;
    display: inline-flex;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    justify-content: space-between;
    padding: 0px 30px;
    opacity: 0;
    transition: all 0.5s ease-in-out;
    pointer-events: none;
}

.ourPillarsSec .ourPillars-outer .content p {
    padding-right: 10px;
}

a.readMoreIconBtn {
    background-color: #FB4F22;
    padding: 25px 23px;
    border-radius: 50px;
    line-height: 1;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.ourPillarsSec .ourPillars-outer .pillars:hover .content {
    opacity: 1;
}

.feacProgramSec {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.feacProgramSec .programContent h6 {
    color: #989292;
    padding-bottom: 5px;
}

.feacProgramSec .container-full {
    overflow: hidden;
}

.feacProgramSec .feacProgram-outer {
    display: grid;
    gap: 30px;
    grid-template-columns: repeat(4, 1fr);
    /* margin-left: -70px; */
}

.feacProgramSec.swiper-wrapper {
    transition-timing-function: linear;
}

.feacProgram-outerCenter.swiper-wrapper {
    transition-timing-function: linear;
}

.pastProgVideoSec {
    min-height: 750px;
}

/* .pastProgVideoSec .swiper-wrapper{
    transition-timing-function:linear;
} */
/* .feacProgramSec .swiper-slide {
    height: 363px;
    width: 570px !important;
} */

.feacProgramSec .swiper.feacProgram-outerCenter {
    margin: 30px 0px 30px 0px;
}

.feacProgramSec .sliderOne .sliderBgImage {
    width: 100%;
    height: 363px;
    object-fit: cover;
    border-radius: 50px;
    z-index: 2;
    position: relative;
}


.sliderOne .content_featured {
    display: block;
    width: 100%;
    height: 100%;
}

/* .feacProgramSec .swiper .swiper-wrapper {
    margin-left: -70px;
} */

/* .feacProgramSec .swiper.feacProgram-outerCenter .swiper-wrapper {
    margin-left: -270px;
} */

.feacProgramSec .sliderOne {
    position: relative;
}

.feacProgramSec .sliderOne .programContent-inner {
    padding-right: 50px;
}


.feacProgramSec .sliderOne {
    transform: translateZ(0);
    backface-visibility: visible !important;
    transform-style: preserve-3d;
}

.feacProgramSec .swiper-backface-hidden .swiper-slide {
    transform: translateZ(0);
    backface-visibility: visible !important;
    transform-style: preserve-3d;
}

.feacProgramSec .sliderOne .programContent {
    position: absolute;
    left: 0px;
    bottom: 0px;
    padding: 0 30px 30px;
    display: inline-flex;
    align-items: flex-end;
    z-index: 99999;
    pointer-events: none;
    backface-visibility: visible;
    transform-style: preserve-3d;

    transform: translate3d(0px, 0px, 20px);
}

.feacProgramSec .sliderOne .programContent-inner p {
    max-width: 265px;
}

/* 
.feacProgramSec .sliderOne:after {
    content: " ";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 50%, rgba(0, 0, 0, 1) 100%);
    z-index: 0;
    opacity: 0.4;
    border-radius: 50px;
} */

.feacProgramSec .sliderOne .programContent a.readMoreIconBtn {
    opacity: 0;
    transition: all 0.5s ease-in-out;
}

.feacProgramSec .sliderOne:hover a.readMoreIconBtn {
    opacity: 1;
}

/* .feacProgramSec .feacProgram-outerCenter {
    margin: 30px -70px 30px 0px !important;
    float: right;
} */

.networkSchoolSec .topHeading p {
    font-size: 16px;
    font-weight: 400;
    color: #d1d1d1;
    width: 1000px;
    margin: 0 auto;
}


.networkSchoolSec .networkSchoolSlider-outer {
    /* display: grid;
    gap: 20px;
    grid-template-columns: repeat(4, 1fr);
    justify-content: center;
    align-items: center; */
    /* width: 1265px; */
    margin: 0 auto;
    position: relative;
}

/* .networkSchoolSec .networkSchoolSlider-outer .swiper-slide {
    width: auto !important;
} */

/* .networkSchoolSec .networkSchoolSlider img {
    width: auto;
} */

.networkSchoolSec .swiper {
    padding-bottom: 0px;
}
.networkSchoolSec .networkSchoolSlider-outer{
    max-width: 90%;
}

span.swiper-pagination-bullet {
    background-color: #D3D3D3;
    opacity: 1;
    width: 10px;
    height: 10px;
    margin: 0px 5px !important;
}

span.swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: #FB4F22;
    position: relative;
}

span.swiper-pagination-bullet.swiper-pagination-bullet-active:after {
    content: " ";
    position: absolute;
    width: 14px;
    height: 14px;
    border: 1px solid #FB4F22;
    border-radius: 50px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.networkSchoolSec .networkSchoolSlider-outer:before {
    content: " ";
    position: absolute;
    left: -57px;
    top: calc(50% - 0px);
    width: 45px;
    height: 125px;
    transform: translateY(-50%);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    background-image: url("../../../public/assets/images/homeImage/gehuIconLeft.svg");
}

.networkSchoolSec .networkSchoolSlider-outer::after {
    content: " ";
    position: absolute;
    right: -57px;
    top: calc(50% - 0px);
    width: 45px;
    height: 125px;
    transform: translateY(-50%);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    background-image: url("../../../public/assets/images/homeImage/gehuIconRight.svg");
}


.pastProgVideoSec .pastProgVideos .progVedioContent {
    display: flex;
    align-items: center;
    background: #fff;
    padding: 30px;
    margin-top: -5px;
    justify-content: space-between;
    position: relative;
}



.pastProgVideoSec .pastProgVideos .progVedioContent p {
    width: 490px;
}



.pastProgVideoSec .swiper-slide-active .pastProgVideos:after {
    content: " ";
    position: absolute;
    top: 31px;
    right: -9px;
    /* width: 110px;
    height: 64px; */
    z-index: 0;
    transition: all 0.5s ease-in-out;
    background-image: url("../../../public/assets/images/homeImage/orgBgElement.svg");
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    /* opacity: 0;
    visibility: hidden; */

}

.pastProgVideoSec .pastProgVideos .progVedioContent:before {
    content: " ";
    position: absolute;
    background-color: #FB4F22;
    height: 65px;
    width: 65px;
    top: -55px;
    left: -10px;
    z-index: 0;
    transition: all 0.5s ease-in-out;
    /* opacity: 0;
    visibility: hidden; */
}


/* .pastProgVideoSec .swiper-slide-active .pastProgVideos .progVedioContent {
    display: flex;
} */


/* .pastProgVideoSec .swiper-wrapper {
    align-items: center;
    padding-left: 40px;
    padding-bottom: 70px;
} */
video.progVideos {
    max-width: 100%;
}

/* 
.pastProgVideoSec .swiper-slide {
    width: 236px !important;
    height: 330px !important;
    object-fit: cover;
}


.pastProgVideoSec .swiper-slide .progVideos {
    width: 236px !important;
    height: 330px !important;
    object-fit: cover;
    z-index: 3;
    position: relative;
} */


/* .pastProgVideoSec .swiper-slide.swiper-slide-active .progVideos {
    width: 40vw !important;
    height: 460px !important;
} */


.pastProgram {
    margin-left: 1.2vw;
}

.mun_logo {
    width: 55% !important;
}


/* .pastProgVideoSec .swiper-slide.swiper-slide-active {
    width: 40vw !important;
}  */
.pastProgVideoSec .progVedioContent,
.pastProgVideoSec .pastProgVideos h4 {
    opacity: 0;
    transition: all 0.5s ease-in-out;
}

/* .pastProgVideoSec .swiper-wrapper{
    margin-left: -10vw;
} */
.pastProgVideoSec .swiper-slide {
    transition: all 0.5s ease-in-out;
    transform: scale(0.8);
    padding: 0 15px;
}

.pastProgVideoSec .swiper-slide.swiper-slide-active {

    transform: scale(1);
}

.pastProgVideoSec .swiper-slide-active .pastProgVideos .progVedioContent,
.pastProgVideoSec .swiper-slide-active .pastProgVideos h4 {
    opacity: 1;
}

.skillSec {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../../../public/assets/images/homeImage/skillSecBg.png");
}

.skillSec .topHeading {
    text-align: left;
    padding-bottom: 20px;
}

.skillSec p.subContent {
    font-size: 20px;
    font-weight: 500;
    width: 500px;
}

.skillSec .skills-outer {
    display: grid;
    gap: 40px;
    grid-template-columns: repeat(3, 1fr);
    width: 1280px;
}

.skillSec .skills-outer .skills {
    background-color: #fff;
    padding: 20px;
    border-radius: 20px;
}

.skillSec .skills-outer .skills img {
    border-radius: 20px;
    padding-bottom: 10px;
}

.skillSec .skills-outer .skills ul.skillList {
    padding-left: 25px;
    list-style: none;
}

.skillSec .skills-outer .skills ul.skillList li {
    color: #3a3a3a;
    position: relative;
}

.skillSec .skills-outer .skills ul.skillList li+li {
    margin-top: 15px;
}

.skillSec .skills-outer .skills ul.skillList li::before {
    content: " ";
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50px;
    left: -20px;
    top: 6px;
    background-color: #FB4F22;

}

.intreactionSec {
    padding-left: 0 !important;
    padding-right: 0 !important;
    position: relative;
    line-height: 0;
}

.intreactionSec .intreactionInner {
    position: absolute;
    bottom: 280px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    z-index: 3;
}

.intreactionSec .intreactionInner h2 {
    line-height: 1;
}

.intreactionSec .intreactionVideo:before {
    content: " ";
    position: absolute;
    top: 4px;
    left: 50%;
    width: 90%;
    height: 100%;
    transform: translateX(-50%);
    background: url("../../../public/assets/images/homeImage/bgOverlap.svg");
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: 100%;
    z-index: 2;
}

.intreactionSec .intreactionVideo:after {
    content: " ";
    position: absolute;
    top: -50px;
    left: 53%;
    width: 91%;
    height: 100%;
    transform: translateX(-50%);
    background: url("../../../public/assets/images/homeImage/interactionIconOver.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    z-index: 2;
}

.intreactionSec .intreactionVideo video {
    width: 100%;
    height: 794px;
    object-fit: cover;
}

.intreactionSec .swiper-button-prev:after {
    content: " ";
    position: absolute;
    width: 14px;
    height: 20px;
    background-image: url("../../../public/assets/images/leftArrowOrg.svg");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
}

.intreactionSec .swiper-button-next::after {
    content: " ";
    position: absolute;
    width: 14px;
    height: 20px;
    background-image: url("../../../public/assets/images/rightArrowOrg.svg");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
}

.intreactionSec .swiper-button-prev,
.intreactionSec .swiper-button-next {
    position: absolute;
    margin: 0px;
    top: initial;
    bottom: 200px;
    width: 42px;
    height: 42px;
    background-color: #fff;
    border-radius: 50px;
}

.intreactionSec .swiper-button-next {
    right: calc(50% - 50px);
}

.intreactionSec .swiper-button-prev {
    left: calc(50% - 50px);
}


.testimonialHomeSec .testimonialImg:before {
    background-color: #FB4F22;
    z-index: 0;
    pointer-events: none;
}

.testimonialHomeSec .testimonialImg img {
    position: relative;
    z-index: 1;
}

.testimonialHomeSec .testimonialImg:after {
    pointer-events: none;
    z-index: 0;
    background-image: url("../../../public/assets/images/homeImage/orgBgElement.svg");
}

.testimonialHomeSec .testimonialImg .testimonialVideo {
    height: 75vh;
    object-fit: cover;
    z-index: 0;
    position: relative;
    width: 100%;
}


/* .partnerHomeSec .swiper-slide {
    filter: saturate(1);
    opacity: 1;
    transition: all 0.5s ease-in-out;
} */

.partnerHomeSec .swiper-slide.swiper-slide-prev,
.partnerHomeSec .swiper-slide.swiper-slide-next,
.partnerHomeSec .swiper-slide.swiper-slide-next+.swiper-slide,
.partnerHomeSec .swiper-slide:has(+ .swiper-slide.swiper-slide-prev) {
    opacity: 1;
    transform: none;
}

.partnerHomeSec .swiper-pagination .swiper-pagination-bullet-active {
    background-color: #FB4F22 !important;
}

/* ================================pillar page css============================== */
.pillarBoxSec {
    position: relative;
}

.boderGradient:before {
    content: " ";
    position: absolute;
    left: 50%;
    top: 0px;
    width: 1000px;
    height: 4px;
    border-image: linear-gradient(to left, #fb4f2200, #FB4F22, #fb4f2200) 1;
    border-bottom: 4px solid;
    transform: translateX(-50%);
}

.pillarBoxSec .pillarBox {

    display: flex;
    align-items: center;
    border-radius: 25px;
    border: 1px solid #ffffff80;
    background-color: #212529;
    margin-bottom: 30px;
}

/* .pillarBoxSec .create {
    min-height: 412px;
} */
/* .pillarBoxSec .Support {
    min-height: 370px;
}
.pillarBoxSec .Engage {
    min-height: 370px;
} */
/* .pillarBoxSec .Innovate {
    min-height: 413px;
} */

.pillarBoxSec .pillarBox .pillarBoxContent {
    padding: 53px 40px;
}

.container.pillarBox-outer {
    display: grid;
    gap: 30px;
    grid-template-columns: 2fr 1fr;
}

.pillarBoxSec .pillarBox-innerRight .pillarBox {
    display: block;
}

.pillarBoxSec .pillarBox img.leftImg {
    border-radius: 25px 0px 0 25px;
    margin-bottom: -3px;
}



.pillarBoxSec .pillarBox img.rightImg {
    border-radius: 0px 25px 25px 0px;
    margin-bottom: -3px;
}

.pillarBoxSec .pillarBox img.topImg {
    border-radius: 25px 25px 0 0px;
    margin-bottom: -3px;
}

.pillarBoxSec .pillarBox .pillarBoxContent h4 {
    line-height: 1;
}


.past_program_container .program-outer {
    width: 50%;
}

.past_program_container {
    text-align: -webkit-center;
}

.video-caption {
    text-align: -webkit-left;
}


.programsSec .program-outer {
    display: flex;
    align-items: center;
    padding-top: 40px;
    /* justify-content: center; */
}

.programsSec .program-outer .program-innerLeft {
    margin-right: 50px;
    padding: 20px 0px;
}

.programsSec .program-outer .program-innerLeft img {
    width: 560px;
    border-radius: 30px;
}

.programsSec .program-outer .program-innerRight {
    margin-left: 50px;
}

.programsSec .program-outer .program-innerRight ul.progarmDetailList {
    list-style: none;
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr;
    padding: 15px 25px;
    background-color: #161616;
    border-radius: 10px;
    margin: 30px 0px;
}

.programsSec .program-outer .program-innerRight h4 span {
    padding-right: 10px;
}

.programsSec .program-outer .program-innerRight ul.progarmDetailList li h6 {
    display: flex;
    align-items: center;
}

.programsSec .program-outer .program-innerRight ul.progarmDetailList li span {
    padding-left: 20px;
}

.programsSec .program-outer .program-innerRight ul.progarmDetailList li img {
    margin-right: 10px;
}

.programsSec .program-outer .program-innerRight .registerBtn {
    display: inline-flex;
    /* justify-content: space-between; */
    width: 180px;
}

.registerBtn.joinbtn {
    padding: 15px 18px !important;
    align-items: center;
    justify-content: center;
}

.programsSec .program-outer .program-innerRight .registerBtn img {
    width: 20px;
}

.plussgn {
    margin-left: 10px;
    font-size: 26px;
}

.programsSec .program-outer .program-innerRight .registerBtn img {
    margin-left: 10px;
}

.readMoreBtn {
    font-size: 16px;
    color: #fff;
    font-weight: 400;
    padding-left: 20px;
}

.programsSec .program-outer .program-innerRight p {
    max-width: 550px;
}







/*Home partners logo slider   */
.partners_logo_home {
    width: 100%;
    padding: 20px 0;
}

.partners_logo_home img {
    width: 70%;
    height: auto;

    object-fit: contain;
}

.partners_logo_home .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
}

.partners_logo_home .swiper-button-next,
.swiper-button-prev {
    color: #333;
}

.partners_logo_home .swiper-pagination-bullet {
    background-color: #333;
}

.partners_logo_home .swiper-pagination-bullet-active {
    background-color: #f00;
}















.oxfordmun_logoslider {
    width: 100%;
    padding: 0px;
}

.oxfordmun_logoslider img {

    max-height: 100px;
    width: 100%;
    /* Ensure the logo fits within the slide */
    height: auto;
    /* Maintain aspect ratio */
    display: block;
    /* Remove extra space below images */
    object-fit: contain;
    /* Ensure logos fit within their container */
}

.oxfordmun_logoslider .swiper-slide {
    display: flex;
    align-items: center;
    /* Center logos vertically */
    justify-content: center;
    /* Center logos horizontally */
}

.oxfordmun_logoslider .swiper-button-next,
.swiper-button-prev {
    color: #000;
    /* Change arrow color if needed */
}

.oxfordmun_logoslider .swiper-pagination-bullet {
    background-color: #000;
    /* Change pagination bullets color if needed */
}

.oxfordmun_logoslider.swiper-pagination-bullet-active {
    background-color: #f00;
    /* Change active bullet color if needed */
}



















.opportunitiesSec .opportunities-outer {
    display: grid;
    gap: 0px;
    grid-template-columns: 1fr 1fr 1fr;
    border: 1px solid #fff;
}

.opportunitiesSec .opportunities-outer .opportunities-wrapper {
    border: 1px solid #fff;
    position: relative;
    line-height: 0;
}

.opportunitiesSec .opportunities-outer .opportunities-wrapper>img {
    filter: grayscale(100%);
    transition: all 0.3s ease-in-out;
}

.opportunitiesSec .opportunities-outer .opportunities-wrapper:hover img {
    filter: none;
}

.opportunitiesSec .opportunities-outer .opportunities-wrapper .contentWrapper {
    position: absolute;
    bottom: 0px;
    padding: 25px;
    z-index: 2;
    line-height: 1.2;
}

.opportunitiesSec .opportunities-wrapper:before {
    content: " ";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 250px;
    opacity: 50%;
    z-index: 1;
}




/* Iframe design */
button.readMoreIconBtn {
    border: none;
    background-color: #FB4F22;
    padding: 25px 23px;
    border-radius: 50px;
    line-height: 1;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

}


.pastProgVideos {
    position: relative;
    /* This makes the iframe position absolute relative to this container */
}

.video-iframe {
    position: absolute;
    top: 36px;
    left: 0;
    right: 0;
    width: 100%;
    height: 63%;
    z-index: 10;

}

/* END */



.opportunitiesSec .opportunities-wrapper.yellowGradient:before {
    background: linear-gradient(180deg, rgba(251, 194, 16, 0) 0%, rgba(251, 194, 16, 1) 90%, rgba(251, 194, 16, 1) 100%);
}

.opportunitiesSec .opportunities-wrapper.purpleGradient:before {
    background: linear-gradient(180deg, rgba(102, 51, 153, 0) 0%, rgba(102, 51, 153, 1) 90%, rgba(102, 51, 153, 1) 100%);
}

.opportunitiesSec .opportunities-wrapper.orgGradient:before {
    background: linear-gradient(180deg, rgba(255, 95, 21, 0) 0%, rgba(255, 95, 21, 1) 90%, rgba(255, 95, 21, 1) 100%);
}

.opportunitiesSec .opportunities-wrapper.orgGradient>img {
    height: 596px;
}

.opportunitiesSec .opportunities-wrapper.blueGradient:before {
    background: linear-gradient(180deg, rgba(46, 187, 223, 0) 0%, rgba(46, 187, 223, 1) 90%, rgba(46, 187, 223, 1) 100%);
}

.opportunitiesSec .opportunities-wrapper.blueGradient>img {
    height: 320px;
}

.opportunitiesSec .opportunities-wrapper.redGradient:before {
    background: linear-gradient(180deg, rgba(229, 36, 60, 0) 0%, rgba(229, 36, 60, 1) 90%, rgba(229, 36, 60, 1) 100%);
}

.opportunitiesSec .opportunities-wrapper.redGradient>img {
    height: 388px;
}

.opportunitiesSec .opportunities-wrapper.greenGradient:before {
    background: linear-gradient(180deg, rgba(76, 158, 55, 0) 0%, rgba(76, 158, 55, 1) 90%, rgba(76, 158, 55, 1) 100%);
}

.opportunitiesSec .opportunities-outer .opportunities-wrapper .contentWrapper a.readMoreBtn {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    text-decoration: none;
}

.opportunitiesSec .opportunities-outer .opportunities-wrapper .contentWrapper a.readMoreBtn img {
    padding-left: 10px;
    width: 25px;
}


.callToActionPillar .callToAction-inner {
    background-image: url("../../../public/assets/images/pillarPage/readyBgOrg.png");
}

.callToActionPillar .callToAction-inner .registerBtn {
    display: inline-flex;
    justify-content: space-between;
    width: 180px;
    background-color: #fff;
    color: #FB4F22;
}

.advenBootcampSec .advenBootcamp-outer {
    display: flex;
    align-items: center;
}

.advenBootcampSec .advenBootcamp-outer .advenBootcamp-innerLeft img {
    width: 560px;
    border-radius: 30px;
}

.advenBootcampSec .advenBootcamp-outer .advenBootcamp-innerLeft {
    padding-right: 50px;
    width: 50%;
    text-align: center;
}

.advenBootcampSec .advenBootcamp-outer .advenBootcamp-innerRight {
    padding-left: 50px;
    width: 50%;
}

.advenBootcampSec .advenBootcamp-outer ul.advenBootcampList {
    padding-left: 25px;
    list-style: none;
    width: 390px;
}

.advenBootcampSec .advenBootcamp-outer ul.advenBootcampList li {
    position: relative;
}

.advenBootcampSec .advenBootcamp-outer ul.advenBootcampList li+li {
    margin-top: 20px;
}

.advenBootcampSec .advenBootcamp-outer .advenBootcamp-innerRight>p {
    line-height: 1.7;
}

.advenBootcampSec .advenBootcamp-outer ul.advenBootcampList li::before {
    content: " ";
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50px;
    left: -20px;
    top: 6px;
    background-color: #FB4F22;
}


/* =====================Network page css-------------------------------- */
.network-school {
    padding-top: 165px !important;
}

.network-school p {
    width: 850px;
    margin: 0 auto;
    text-align: center;
}






.testimonialvideo .pastProgVideos {
    position: relative;
}

.testimonialvideo .testimonialvideoInner .swiper-slide.swiper-slide-active .pastProgVideos:after {
    top: -8px;
    right: -10px;
}

.testimonialvideo .testimonialvideoInner .swiper-slide.swiper-slide-active .pastProgVideos:before {
    content: " ";
    position: absolute;
    background-color: #FB4F22;
    height: 65px;
    width: 65px;
    bottom: -6px;
    left: -10px;
    z-index: 0;
    transition: all 0.5s ease-in-out;
}

.testimonialvideo .testimonialvideoInner {
    overflow: visible;
}

.testimonialvideo .testimonialvideoInner .swiper-wrapper {
    align-items: end;
    padding-left: 0;
}

.bgwhite {
    background-color: #fff;
    border-radius: 14px;
    padding: 70px;
}

.school-logo {
    position: relative;
}

.school-logo.boderGradient:before {
    height: auto;
}

.school-logo-outer {
    display: grid;
    gap: 40px;
    grid-template-columns: repeat(8, 2fr);
}

.benefits .program-outer {
    justify-content: space-between;
}

.benefits .program-innerRight {
    padding-left: 0px !important;
    padding-right: 50px;
}

.benefits .program-innerLeft {
    padding-right: 0px !important;
    padding-left: 50px;
}

.benefits .program-outer .program-innerRight>p {
    padding-bottom: 45px;
}

.benefits .program-innerRight ul.freestructure {
    color: #fff;
    list-style: number;
    margin-left: 20px;
    padding-bottom: 45px;
}

.benefits .program-innerRight .freestructure li+li {
    padding-top: 5px !important;
}

.benefits .program-innerRight a.registerBtn {
    margin-right: 10px;
    width: auto !important;
}

.download-brochure {
    padding: 11px 35px;
    color: #fff;
    border-radius: 50px;
    font-size: 18px;
    font-weight: 600;
    text-decoration: none;
    line-height: 1;
    border: 2px solid #fff;
    display: inline-flex;
    align-items: center;
}

.download-brochure .widthAuto {
    margin-right: 10px;
}

.joinour-chain .contactPopup {
    width: auto;
    background-color: #000;
    padding: 0;
    grid-template-columns: repeat(2, 1fr);
}

.joinour-chain .contactDetail-outer {
    display: flex;
    align-items: center;
}

.joinour-chain .contactForm {
    background-color: #212529 !important;
}

.loaction {
    display: grid;
    grid-template-columns: auto auto;
    gap: 20px;
}

.benefits .program-outer {
    padding-top: 0px !important;
}

.benefits h3 {
    width: 526px;
}

select:focus {
    outline: none;
}

.joinour-chain .contactForm input,
.joinour-chain .contactForm textarea {
    height: 55px;
    padding: 15px 20px;
}

.joinour-chain .contactForm textarea {
    height: 110px !important;
}


select.opt_form {
    background: url(https://musite.b-cdn.net/videos/dropdown.png) no-repeat;
    background-size: 20px 13px;
    background-position: right 10px center;
    padding-right: 40px;
    font-size: 16px;
    line-height: 1.5;
    border: 1px solid #ccc;
    border-radius: 4px;
    appearance: none;
}

/* Hide the default dropdown arrow in Internet Explorer */
select.opt_form::-ms-expand {
    display: none;
}

/* Optional: Add custom styles for better look */
select.opt_form {
    border: 1px solid #ededed;
    border-radius: 4px;
    background-color: white;
}




.loaction {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr;
}

.contactPopup .contactForm {

}

select {

    width: 100%;
    height: 55px;
    padding: 10px 40px;
    border-radius: 10px !important;
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.2;
    color: #000;
    border: none;
    font-family: 'Euclid Circular A';
}

select option {
    color: #000;
}

select option:disabled {
    color: #b7b3b0;
}

/* =======================================Contact Popup css--------------------------------------------- */

html body .popup-overlay {
    background: rgba(0, 0, 0, 0.8);
}

html body .popup-content {
    margin: auto;
    background: #fff;
    width: 100%;
    padding: 5px;
    border: 1px solid #d7d7d7;
    max-width: 750px;
    /* overflow: hidden; */
    padding: 0;
    border: navajowhite;
    border-radius: 15px;
}

html body .popup-content .bgBlack {
    border-radius: 15px;
}

button.close {
    position: absolute;
    right: -9px;
    top: -9px;
    background: #fff;
    border: none;
    border-radius: 50px;
    width: 29px;
    height: 29px;
    font-size: 25px;
    cursor: pointer;
}


.contactPopup {
    padding: 20px;
    display: grid;
    gap: 70px;
    /* grid-template-columns: repeat(2, 1fr); */
    width: auto;
    margin: 0 auto;
    border-radius: 20px;
}

.contactPopup .contactDetail-outer {
    padding: 30px 20px;
}

.contactPopup .contactDetail-outer .contactDetail a.textEmail {
    background-color: #2D2D2D;
    padding: 5px 10px;
    border-radius: 6px;
}

.contactPopup .contactDetail-outer .contactDetail a.textEmail span {
    display: inline-flex;
    align-items: center;
}

.contactPopup .contactDetail-outer .contactDetail a.textEmail span img {
    margin-right: 5px;
}

.contactPopup .contactDetail-outer>img {
    width: 236px;
}


.contactPopup .contactDetail-outer .contactDetail {
    width: 445px;
    padding-bottom: 10px;
}

.contactPopup .contactDetail-outer .contactDetail h3 {
    padding: 0 50px 10px 0px;
}

.contactPopup .contactForm {
    background-color: #000;
    border-radius: 20px;
    padding: 40px;
}


.contactPopup .contactForm label {
    display: none;
}

.contactPopup .contactForm input,
.contactPopup .contactForm textarea {
    width: 100%;
    height: 55px;
    padding: 20px 40px;
    border-radius: 10px;
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.2;
    color: #000;
    border: none;
    font-family: 'Euclid Circular A'

}

.contactPopup .contactForm input:focus,
.contactPopup .contactForm textarea:focus {
    border: none;
    outline: none;
}

.contactPopup .contactForm input::placeholder,
.contactPopup .contactForm textarea::placeholder {
    color: #00000066;
    font-family: 'Euclid Circular A'
}

.contactPopup .contactForm textarea {
    height: 155px;
}

.contactPopup .contactForm button.formSubmit {
    background-color: #FB4F22;
    padding: 14px 40px;
    color: #fff;
    border-radius: 50px;
    font-size: 18px;
    font-weight: 600;
    border: none;
    cursor: pointer;
}

.contactForm.form-success form {
    opacity: 0;
}





.form-message {
    display: none;
}

.form-message.form-success {
    display: block;
}

.form-message {
    position: relative;
    text-align: center;
    padding: 70px;
    width: 100%;
    margin: 0 auto;
}

.success-image {
    display: block;
    margin: 0 auto;
    max-width: 60px;
    height: auto;
}


.success_logo img {
    margin-top: 20px;
    width: 25%;
}

.thnx_msg {
    margin-top: 10px;
    font-size: 24px;
    color: #6CEBB6;
}

.success-message {
    color: #ffffff;
    line-height: 1.5;
    font-size: 16px;
    margin-top: 20px;
}

.form-success form {
    display: none;
}





/* .contactForm.form-success:after {
    content: "Thank you for reaching out to us! We’ve received your query and our team will get back to you as soon as possible. Looking forward to assisting you!   Regards, Team MU20 School of Opportunity";
    color: #1ab91a;
    text-align: center;
    width: 50%;
    position: absolute;
    line-height: 21px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
} */
.contactForm.form-success {
    position: relative;
}

form.form-error {
    position: relative;
}

form.form-error:after {
    border: solid 1px #f00;
    border-radius: 5px;
    content: "Error: Please ensure that all fields are completed.";
    width: auto;
    height: 30px;
    display: none;
    position: absolute;
    bottom: 10px;
    right: 0px;
    color: #fff;
    font-size: 12px;
    background: #f00;
    padding: 0 10px;
    line-height: 30px;
}

.page_Heading {
    max-width: 880px;
    margin: 0 auto;
    padding: 0px;
    border-radius: 8px;
}

p.heading_cont {
    padding: 20px 20px 0px 20px;
}



.testimonialVideo {
    z-index: 1;
    position: relative;
}

.textWrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2;
    pointer-events: none;
    /* This ensures the textWrapper does not interfere with the video controls */
}

button.org_btn.registerBtn.joinbtn {
    background-color: #FB4F22;
}

.pillarBox.empower.m-0 {
    min-height: 79.7vh;
}


.pillarBox.collaborate.m-0 {
    min-height: 83.5vh;
}


.pillarBoxContent.Sustain {
    min-height: 43.5vh;
}






@media screen and (max-width: 1600px) {
    .fs-34 {
        font-size: 30px !important;
    }
}


@media screen and (min-width: 1500px) {
     .fs-34 {
        font-size: 26px !important;
    }
}
  

@media screen and (min-width: 1600px) {
    .fs-34 {
        font-size: 30px !important;
    }
}





@media screen and (max-width: 1440px) {

    .pillarBox.m-0.sustain {
        min-height: 89.5vh;
    }

    .pillarBox.collaborate.m-0 {
        min-height: 89.5vh;
    }

    .networkSchoolSec .networkSchoolSlider-outer:before {
        left: -37;
    }

    .networkSchoolSec .networkSchoolSlider-outer:after {
        right: -37;
    }

    .networkSchoolSec .swiper {
        max-width: 90%;
    }

    .container.opportunities-outer {
        padding: 0px;
    }

    .fs-34 {
        font-size: 26px !important;
    }


    .date_bold {
        color: white;
        font-weight: bold;
        font-size: 18px;
    }


    /* .iframe-container_left, .iframe-container_right{
       
        height: 312px;
        width: 100%;
       
      }
      .iframe-container_left iframe, .iframe-container_right iframe{
        height: 100% !important;
        
      } */




    .pillarBoxSec .Support {
        min-height: 310px;
    }

    .pillarBoxSec .create {
        min-height: 370px;
    }

    .pillarBoxSec .Engage {
        min-height: 323px;
    }

    .pillarBox.empower.m-0 {
        min-height: 85.5vh;
    }

    .pastProgVideoSec {
        min-height: 75vh;
    }

    .container {
        padding: 20px;
        max-width: 100%;
        margin: 0 auto;
    }

    .p-80-20 {
        padding: 60px 20px !important;
    }

    .oxfordBanner .banner-content h1,
    .secTitle,
    .fs-80 {
        font-size: 42px !important;
    }

    p {
        font-size: 17px;
    }

    .pt-0 {
        padding-top: 0px !important;
    }

    .fs-16 {
        font-size: 14px !important;
    }

    .fs-18 {
        font-size: 17px !important;
    }

    .fs-22 {
        font-size: 16px !important;
    }

    .fs-25 {
        font-size: 18px !important;
    }

    .fs-32 {
        font-size: 20px !important;
    }

    .fs-34 {
        font-size: 22px !important;
    }

    .fs-42 {
        font-size: 36px !important;
    }

    .secTitle {
        padding-bottom: 50px;
    }

    .whyOxfordSec {
        padding: 100px 20px !important;
    }

    .whyOxfordSec .oxford-box h3 {
        width: 100%;
        padding-bottom: 10px;
    }

    .whyOxfordSec .column .oxford-box p {
        padding-right: 0;
    }

    .eventSec,
    .conference,
    .partnerSec,
    .callToAction {
        padding: 50px 20px;
    }

    .confere-box h3 {
        padding-bottom: 15px;
    }

    .testimonialSec {
        padding: 50px 20px 80px;
    }

    .reviewSec {
        padding: 80px 0 0;
    }

    .faqSec {
        padding: 0px 20px 80px;
    }

    button.join {
        padding: 13px 30px;
    }

    h3 {
        font-size: 22px;
    }

    h4 {
        font-size: 17px;
    }

    .eventSec .eventBox .eventTitle {
        font-size: 24px;
    }

    .eventSec .eventBox p {
        font-size: 16px;
    }

    /* .eventSec .eventBox {
        width: 100%;
    } */

    .eventSec .eventBox .eventContentBox {
        min-height: 215px;


    }

    .venueSpotSec .venueSpot-inner img {
        width: 700px;
    }

    .venueSpotSec .venueSpot-inner .venueSpotContent {
        padding: 40px 40px 40px 110px;
    }

    .venueSpotSec .venueSpot-inner .image-gallery-thumbnail {
        width: 120px;
    }

    .eventSec .swiper-button-next:after,
    .eventSec .swiper-button-prev:after {
        width: 18px;
        height: 18px;
    }

    .eventSec .swiper-button-prev,
    .eventSec .swiper-button-next {
        width: 40px;
        height: 40px;
    }

    .eventSec .swiper-button-prev {
        right: 65px;
    }

    .eventSec .eventBox .eventDate {
        font-size: 26px;
        padding: 13px;
    }

    .eventSec .eventBox .eventDate span {
        font-size: 14px;
    }

    .venueSpotSec .venueSpot-inner .venueSpotContent h2 {
        font-size: 30px;
        padding-bottom: 20px;
    }

    .testimonialSec .testimonialImg .titleWatch {
        font-size: 28px;
    }

    /* .reviewSec .reviewContent {
        width: 480px;
        bottom: 25px !important;
    }

    .reviewSec .swiper-button-prev {
        left: calc(50% - 215px);
        bottom: 72px;
    }

    .reviewSec .swiper-button-next {
        right: calc(50% - 215px);
        bottom: 72px;
    } */

    .joinSec .logoImage img {
        width: 90%;
    }

    .joinSec .reportimpact-img img {
        width: 500px;
    }

    .joinSec .content-wrapperCol .content-wrapper .contentTitle {
        font-size: 30px;
        margin-bottom: 25px;
    }

    .callToAction .callToAction-inner h5 {
        font-size: 30px;
    }

    .faqSec .faqInner {
        padding: 60px;
    }

    .faqSec .faqInnerRight {
        width: 60%;
        padding-left: 50px;
    }

    .faqSec .faqInnerLeft {
        width: 40%;
        margin-right: 20px;
        padding: 50px;
    }

    .callToAction .callToAction-inner .callToActionRight p {
        padding-bottom: 40px;
    }

    .registerBtn {
        font-size: 17px;
    }


    .ourPillarsSec .ourPillars-outer .titleTop h4 {
        margin-bottom: 10px;
    }

    .networkSchoolSec .topHeading p {
        font-size: 15px;
    }

    .networkSchoolSec .networkSchoolSlider-outer {
        /* max-width: 850px;
        width: 100%; */
    }

    .pastProgVideoSec .swiper-wrapper {
        padding-left: 0;
    }

    a.readMoreIconBtn {
        padding: 20px 18px;
    }

    .skillSec p.subContent {
        font-size: 18px;
    }

    .skillSec .skills-outer {
        gap: 30px;
        width: 1050px;
    }

    .skillSec .skills-outer .skills ul.skillList li::before {
        width: 8px;
        height: 8px;
    }

    .intreactionSec {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .intreactionSec .intreactionVideo:after {
        top: 0;
    }

    .intreactionSec .swiper-button-prev,
    .intreactionSec .swiper-button-next {
        width: 38px;
        height: 38px;
    }

    .intreactionSec .swiper-button-prev:after,
    .intreactionSec .swiper-button-next::after {
        width: 10px;
        height: 15px;
    }

    /* .testimonialHomeSec .testimonialImg .testimonialVideo {
        height: 550px;
    } */

    /* .feacProgramSec .swiper-slide {
        height: 300px;
        width: 500px !important;
    } */

    .feacProgramSec .sliderOne .sliderBgImage {
        height: 300px;
    }

    .download-brochure {
        font-size: 16px;
        padding: 10px 30px;
    }

    .benefits .program-innerRight {
        padding-right: 0;
    }

    .benefits .registerBtn {
        font-size: 16px;
        padding: 16px 30px;
    }

    .network-school {
        padding-top: 150px !important;
    }

    .pillarBoxSec .pillarBox img {
        width: 100%;
    }

    .pillarBoxSec .pillarBox img.topImg {
        width: 100%;
    }

    .pillarBoxSec .pillarBox .pillarBoxContent {
        padding: 66px 40px;
    }

}





@media screen and (max-width: 1024px) {

    .mt-50{
        margin-top: 0px;
    }

    .pillarBox.empower.m-0 {
        min-height: 68.5vh;
    }

    .form-message {
        padding: 0px;
    }

    .menu_header_bg {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.8);
        /* Semi-transparent black background */
        transform: translateX(-100%);
        transition: all 0.5s ease-in-out;
        z-index: 9999;
        display: block;
        background-image: url('https://musite.b-cdn.net/videos/student_trained.jpeg');
        opacity: 0;

        background-size: cover;
        background-position: center;
    }


    .menu_header_bg.show {
        transform: translateX(0);
        opacity: 1;
    }


    /* menu_header.html CSS is here  */


    html body .mobile-flap-nav-toggle {
        display: none;
    }

    /* Add background image with black color and low opacity */


    /* Add black overlay with low opacity */
    .menu_header_bg::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        /* Black with 50% opacity */
        z-index: 1;
    }

    /* Center align content in this class */
    .meu_part {
        position: relative;
        z-index: 2;
        text-align: center;
        padding: 20px;
    }

    /* Center and size logo */
    .menu_logo {
        margin-bottom: 100px;
    }

    .menu_logo img {
        max-width: 30%;
        /* Adjust as needed */
        height: auto;
    }

    /* Style menu items vertically */
    .menu_items {
        list-style-type: none;
        padding: 0;
        margin: 0;
    }

    .menu_items li {
        margin: 15px 0;
        /* Adjust spacing as needed */
        font-size: 18px;
        /* Adjust font size as needed */
    }

    /* Optional: Style menu items to look like header menu */
    .menu_items li {
        display: block;
        color: #fff;
        /* Text color */
        font-weight: bold;
    }

    .menu_link {
        font-size: 22px;
        text-decoration: none;
        /* Remove underline */
        color: #fff;
        /* Text color */
        display: block;
        padding: 10px 20px;
        border-radius: 5px;
        /* Rounded corners */
        transition: background-color 0.3s, color 0.3s;
    }

    .menu_link:hover {
        background-color: rgba(255, 255, 255, 0.3);
        /* Light background on hover */
        color: #000;
        /* Change text color on hover */
    }

    /* ---- */















    .partnerLogo {
        max-width: 70%;
    }

    .mentors_logos .partnerLogo {
        margin: 0px 20px;

    }


    .joinSec .impact-report-box {

        padding: 0px 0px 40px !important;
    }

    .gray_theme .whyOxfordSec {
        padding: 80px 20px !important;
    }

    .container {
        padding: 0px !important;
    }

    .partnerSec.communityPartnerSec,
    .experienceSec.p-80-20 {
        overflow: hidden;
    }

    .green_theme .callToAction .callToAction-inner,
    .gray_theme .callToAction .callToAction-inner {
        padding: 40px 40px !important;
    }

    .faqSec .faqInnerLeft {

        width: 100% !important;
    }

    .opportunitiesSec .opportunities-outer .opportunities-wrapper .contentWrapper {
        padding: 8px;
    }

    .gray_theme .callToAction .callToAction-inner {
        padding: 80px 20px;
    }


    .programsSec .program-outer .program-innerRight p {
        max-width: 100%;

    }

    .video-caption {
        padding: 10px 0;
    }

    /* .iframe-container_left , .iframe-container_right{
        height: 84%;
      }
     
      .iframe-container_left iframe,  .iframe-container_right iframe{
        width: 100%;
        height: 100% !important;
      }
    */


    .w-50 {

        width: 100% !important;
    }


    .green_theme .faqSec .faqInnerLeft,
    .blue_theme,
    .faqSec .faqInnerLeft,
    .red_theme .faqSec .faqInnerLeft,
    .gray_theme .faqSec .faqInnerLeft {
        width: 100%;
        margin-bottom: 60px;
    }

    .faqSec .faqInnerRight .secTitle {
        text-align-last: center;

    }

    .pastProgVideoSec {
        min-height: 550px;
    }

    .feacProgramSec .sliderOne .programContent-inner {
        padding: 0px;
    }

    /* .partnerSec .networkSchoolSlider-outer{
        width: 100%;
    } */

    .oxfordBanner .banner-content h1,
    .secTitle,
    .fs-80 {
        font-size: 36px !important;
    }

    .pb-10 {
        padding-bottom: 5px !important;
    }

    .fs-18 {
        font-size: 16px !important;
    }

    .fs-22 {
        font-size: 14px !important;
    }

    .fs-34 {
        font-size: 20px !important;
    }

    .introduction p {
        width: 100%;
    }

    p {
        font-size: 17px;
    }

    h3 {
        font-size: 22px;
    }

    .fs-42 {
        font-size: 32px !important;
    }

    .pb-20 {
        padding-bottom: 10px !important;
    }

    .fs-25 {
        font-size: 16px !important;
    }

    .secTitle {
        padding-bottom: 40px;
    }

    .topHeading {
        padding-bottom: 40px;
    }

    .intro-section,
    .eventSec,
    .conference,
    .partnerSec,
    .callToAction {
        padding: 80px 20px;
    }

    .whyOxfordSec {
        padding: 80px 20px !important;
    }

    .whyOxfordSec .oxford-box h3 {
        width: 100%;
        padding-right: 15px;
    }

    .whyOxfordSec .inner-box {
        border-radius: 30px;
        padding: 40px;
    }

    .whyOxfordSec .row {
        gap: 60px;
    }

    .whyOxfordSec .column .oxford-box p {
        padding-right: 0;
    }

    .whyOxfordSec .column .oxford-box:first-child {
        padding-bottom: 30px;
        margin-bottom: 30px;
    }

    .confere-detail {
        padding: 0px;
    }

    .confere-box {
        border-radius: 20px;
        padding: 25px 30px 25px 25px;
        margin-bottom: 30px;
    }

    .confere-box h3,
    .conference .confere-reg h3 {
        padding-bottom: 15px;
    }

    .conference .confere-detail .confere-box:before {
        right: 20px;
        width: 10px;
    }

    .conference .confere-detail .row {
        gap: 30px;
    }

    .conference .confere-box span.bgColorText {
        font-size: 16px;
        padding: 3px 7px;
    }

    .conference .confere-reg p {
        padding-bottom: 35px;
    }

    .registerBtn {
        margin-bottom: 0px;
        padding: 12px 21px;
        font-size: 16px;
    }

    .conference .confere-reg {
        border-radius: 20px;
        padding: 25px;
    }

    .eventSec .swiper-button-prev,
    .eventSec .swiper-button-next {
        top: -50px;
        width: 35px;
        height: 35px;
    }

    .eventSec .swiper-button-prev {
        right: 60px;
    }

    .eventSec .swiper {
        padding: 0 0px;
    }

    .eventSec .eventBox img {
        border-radius: 20px 20px 0 0;
        height: 240px;
    }

    .eventSec .eventBox .eventTitle {
        font-size: 22px;
    }

    .eventSec .eventBox .eventContentBox {
        padding: 40px 20px 30px;
        min-height: 191px;
    }

    .eventSec .eventBox .eventDate {
        font-size: 22px;
        padding: 10px;
    }

    .eventSec .eventBox .eventDate span {
        font-size: 13px;
        padding-top: 6px;
    }

    .partnerSec .swiper-pagination .swiper-pagination-bullet {
        width: 8px;
        height: 8px;
        margin: 4px !important;
    }

    .partnerSec .swiper-pagination .swiper-pagination-bullet-active:before {
        width: 12px;
        height: 12px;
    }

    .partnerSec .swiper {
        padding-bottom: 30px;
    }

    .venueSpotSec .venueSpot-inner {
        display: block;
        padding-top: 40px;
        padding-bottom: 0px;
    }

    .venueSpotSec {
        padding: 80px 20px !important;
    }

    .venueSpotSec .venueSpot-inner .venueSpotContent {
        margin: 0px;
        border: none;
        padding: 40px 0px 0px;
    }

    .venueSpotSec .venueSpot-inner img {
        width: 100%;
    }

    .venueSpotSec .venueSpot-inner .image-gallery-slides {
        border-radius: 20px;
    }

    .venueSpotSec .venueSpot-inner .image-gallery-thumbnail {
        width: 120px;
    }

    .venueSpotSec .venueSpot-inner .venueSpotContent h2 {
        font-size: 28px;
    }

    .testimonialSec {
        padding: 0px 20px 80px 20px;
    }

    .testimonialSec .testimonialImg .titleWatch {
        font-size: 24px;
    }

    .reviewSec {
        padding: 0px !important;
    }

    .reviewSec .reviewContent {
        padding: 25px 50px;
        bottom: 20px;
        /* width: 422px; */
    }

    /* .reviewSec .swiper-button-prev {
        left: calc(50% - 185px);
    }

    .reviewSec .swiper-button-next {
        right: calc(50% - 185px);
    } */

    .reviewSec .swiper-slide img {
        /* min-height: 600px; */
        object-fit: cover;
    }

    .reviewSec .swiper-button-prev:after,
    .reviewSec .swiper-button-next::after {
        width: 12px;
        height: 18px;
    }

    .reviewSec .swiper-button-prev,
    .reviewSec .swiper-button-next {
        bottom: 42px;
        width: 12px;
        height: 18px;
    }

    .reviewSec .reviewContent p span {
        font-size: 18px;
    }

    .joinSec .logoImage {
        width: 40%;
    }

    .joinSec .reportimpact-img {
        width: 40%;
    }

    .joinSec .content-wrapperCol {
        width: 60%;
        padding-right: 20px;
    }

    .joinSec {
        padding: 80px 20px;
    }


    .joinSec .reportimpact-img img {
        width: 100%;
    }

    .joinSec .content-wrapperCol .content-wrapper .contentTitle {
        font-size: 28px;
        margin-bottom: 20px;
    }

    .callToAction .callToAction-inner {
        padding: 30px 50px;
        border-radius: 25px;
    }

    .callToAction .callToAction-inner h5 {
        font-size: 26px;
    }

    .callToAction .callToAction-inner .callToActionRight p {
        padding-bottom: 20px;
        width: 100%;
    }

    .faqSec .faqInner p {
        padding-bottom: 40px;
    }

    .faqSec .faqInner {
        display: block;
        padding: 40px;
        border-radius: 20px;
    }

    .faqSec .faqInnerLeft img {
        width: 150px;
    }

    .faqInnerLeft .faqSec .faqInnerLeft {
        width: 100%;
        margin-right: 0px;
        margin-bottom: 50px;
        padding: 30px;
    }

    .faqSec .faqInnerRight {
        width: 100%;
        padding: 20px;
    }

    .faqSec .faqInnerRight .faqBlock .accordion .accordion__button {
        padding: 30px 20px;
    }

    .faqSec .faqInnerRight .faqBlock .accordion .accordion__panel {
        padding: 0px 40px 30px 60px;
    }

    .faqSec .faqInnerRight .faqBlock .accordion .accordion__button:before {
        width: 12px;
        height: 12px;
    }

    .faqSec {
        padding: 0px 0px 80px !important;
    }

    .networkSchoolSec .topHeading p {
        font-size: 14px;
        width: 500px;
    }

    .networkSchoolSec .networkSchoolSlider-outer {
        /* width: 580px; */
    }

    .networkSchoolSec .networkSchoolSlider-outer::after {
        right: -37px;
        width: 35px;
        height: 100px;
        top: calc(50% - 0px);
    }

    .partnerSec .networkSchoolSlider-outer::after {
        right: 0px;
        width: 35px;
        height: 100px;
        top: calc(50% - 25px);
    }

    .partnerSec .networkSchoolSlider-outer:before {
        left: 0px;
        width: 35px;
        height: 100px;
        top: calc(50% - 25px);
    }



    .networkSchoolSec .networkSchoolSlider-outer:before {
        left: -37px;
        width: 35px;
        height: 100px;
        top: calc(50% - 0px);
    }

    .networkSchoolSec .networkSchoolSlider img {
        width: auto;
        max-height: 100px !important;
    }

    span.swiper-pagination-bullet {
        width: 8px;
        height: 8px;
        margin: 0px 4px !important;
    }

    span.swiper-pagination-bullet.swiper-pagination-bullet-active:after {
        width: 12px;
        height: 12px;
    }

    /* .pastProgVideoSec .swiper-slide.swiper-slide-active .progVideos {
        width: 550px !important;
        height: 300px !important;
    } */

    /* .pastProgVideoSec .swiper-slide.swiper-slide-active {
        width: 550px !important;
    } */

    /* .pastProgVideoSec .swiper-slide .progVideos,
    .pastProgVideoSec .swiper-slide {
        width: 200px !important;
        height: 300px !important;
    } */

    .pastProgVideoSec .swiper-wrapper {
        padding-left: 20px;
    }

    .skillSec .skills-outer {
        gap: 20px;
        width: 100%;
    }

    .skillSec p.subContent {
        font-size: 16px;
    }

    .skillSec {
        background-position: top right;
        background-size: contain;
    }

    .skillSec .skills-outer .skills {
        padding: 10px;
        border-radius: 10px;
    }

    .skillSec .skills-outer .skills img {
        border-radius: 10px;
    }

    .skillSec .skills-outer .skills ul.skillList li+li {
        margin-top: 10px;
    }

    .skillSec .skills-outer .skills ul.skillList {
        padding-left: 20px;
    }

    .skillSec .skills-outer .skills ul.skillList li::before {
        width: 6px;
        height: 6px;
        left: -15px;
    }

    .intreactionSec .intreactionVideo video {
        height: 500px;
    }

    .intreactionSec .intreactionInner {
        bottom: 160px;
    }

    .intreactionSec .swiper-button-prev,
    .intreactionSec .swiper-button-next {
        bottom: 100px;
    }

    .intreactionSec .intreactionVideo:after {
        top: 80px;
    }

    .intreactionSec .swiper-button-prev,
    .intreactionSec .swiper-button-next {
        width: 35px;
        height: 35px;
    }

    .intreactionSec .swiper-button-next {
        right: calc(50% - 45px);
    }

    .intreactionSec .swiper-button-prev {
        left: calc(50% - 45px);
    }

    /* .testimonialHomeSec .testimonialImg .testimonialVideo {
        height: 350px;
    } */

    .ourPillarsSec .ourPillars-outer {
        grid-template-columns: repeat(2, 1fr);
    }

    /* .ourPillarsSec .ourPillars-outer .pillars .bgPillarsImg {
        height: 550px;
    } */

    /* .feacProgramSec .swiper-slide {
        height: 270px;
        width: 430px !important;
    } */

    .feacProgramSec .sliderOne .sliderBgImage {
        height: 270px;
        border-radius: 30px;
    }

    /* .feacProgramSec .sliderOne:after {
        border-radius: 30px;
    } */

    .school-logo-outer {
        gap: 30px;
        grid-template-columns: repeat(3, 1fr);
    }

    .benefits .program-innerLeft {
        padding: 0px !important;
    }

    .benefits .program-outer .program-innerRight {
        padding-bottom: 30px;
    }

    .joinour-chain .contactPopup {
        grid-template-columns: repeat(1, 1fr);
        gap: 30px;
    }

    .joinour-chain .contactPopup .contactDetail-outer .contactDetail h3 {
        padding: 0 0px 10px 0px;
    }

    .joinour-chain .contactPopup .contactDetail-outer {
        padding: 0px 20px;
    }

    .joinour-chain .contactPopup .contactDetail-outer .contactDetail {
        width: 100%;
    }

    .contactPopup .contactForm {
        padding: 30px;
    }

    html body .popup-content {
        width: 95%;
    }

    .network-school {
        padding-top: 130px !important;
    }

    .network-school p {
        width: 100%;
    }

    .boderGradient:before {
        width: 80%;
    }

    .benefits h3 {
        width: 100%;
    }

    .programsSec .program-outer .program-innerLeft img {
        border-radius: 20px;
    }

    .programsSec .program-outer .program-innerRight .registerBtn {
        width: 160px;
    }

    .opportunitiesSec .opportunities-wrapper.blueGradient>img,
    .opportunitiesSec .opportunities-wrapper.orgGradient>img,
    .opportunitiesSec .opportunities-wrapper.redGradient>img {
        height: auto;
    }

    .callToActionPillar .callToAction-inner .registerBtn {
        width: 150px;
    }

    .advenBootcampSec .advenBootcamp-outer {
        display: block;
    }

    .advenBootcampSec .advenBootcamp-outer .advenBootcamp-innerLeft {
        padding: 0px 0 30px;
        width: 100%;
    }

    .advenBootcampSec .advenBootcamp-outer .advenBootcamp-innerLeft img {
        width: 100%;
        border-radius: 20px;
    }

    .advenBootcampSec .advenBootcamp-outer .advenBootcamp-innerRight {
        padding-left: 0;
        width: 100%;
    }

    .advenBootcampSec .advenBootcamp-outer ul.advenBootcampList {
        width: 100%;
    }

    .advenBootcampSec .advenBootcamp-outer ul.advenBootcampList li::before {
        width: 8px;
        height: 8px;
    }

    .container.pillarBox-outer {
        gap: 30px;
        grid-template-columns: 1fr;
    }

    .oxfordBanner .banner-content .muteBtn {
        margin-right: 30px;
        width: 30px;
        height: 30px;
    }

    video.sliderBgImage {
        width: 100%;
    }


}

@media screen and (max-width: 767px) {


    .pillarBox.m-0.sustain {

        min-height: 30.5vh;

    }

    .partners_logo_home img {
        width: 70%;
        /* Ensure logos fit within the slide */
    }

    .oxfordmun_logoslider img {
        max-height: 75px;
    }

    .venueSpotSec .venueSpot-inner .venueSpotContent {
        padding: 20px 20px 20px 20px !important;
    }

    .venueSpotSec .venueSpot-inner img {
        width: 100% !important;
    }

    .programsSec .program-outer {
        padding-top: 20px !important;
    }

    .mun_logo img {
        width: 100% !important;
    }


    button.readMoreIconBtn {
        padding: 18px 16px;
    }

    .pastProgVideoSec .swiper-horizontal {
        max-height: 360px !important;
    }

    .intreactionVideo img {
        height: 500px !important;
    }

    .menu_logo img {
        max-width: 50%;
        /* Adjust as needed */
        height: auto;
    }


    .success-message {
        font-size: 14px;
    }

    select.opt_form {
        background-size: 13px 7px;
    }

    form.form-error:after {
        bottom: -40px;
        padding: 4px 10px;
        line-height: 11px;
        font-size: 11px;
        height: 21px;

    }


    .date_bold {
        color: white;
        font-weight: bold;
        font-size: 14px;
        padding: 0;
    }

    .date_bold span {
        line-height: 1.2em;
    }

    .mentors_logos {

        display: block;
        text-align: -webkit-center;
    }

    .mentors_logos .partnerLogo {
        margin: 40px 0px;
        width: 60%;
    }


    .past_program_container .program-outer {
        width: 100%;
    }

    .gray_theme .whyOxfordSec {
        padding: 40px 20px !important;
    }

    .gray_theme .intro-section {
        background-color: #98989812;

    }

    /* .iframe-container_left{
        height: 74%;
      }
      .iframe-container_right{
        height: 80%;
      }
     
      .iframe-container_left iframe,  .iframe-container_right iframe{
        width: 100%;
        height: 100% !important;
      }
     */
    .red_theme .whyOxfordSec {
        padding: 20px !important;
    }

    .callToAction .callToAction-inner {
        padding: 80px 20px !important;
    }

    .faqSec .faqInnerLeft {
        padding: 20px !important;
    }




    .joinSec .impact-report-box {
        padding: 0px !important;
    }



    .w-50 {
        width: 100% !important;
    }

    video.sliderBgImage {
        width: 100% !important;

    }

    .green_theme .faqSec .faqInnerLeft {
        padding: 40px;
    }

    .green_theme .whyOxfordSec {
        padding: 60px 20px !important;
    }

    .green_theme .callToAction .callToAction-inner,
    .gray_theme .callToAction .callToAction-inner {
        padding: 40px 40px !important;
    }

    .gray_theme .faqSec .faqInnerLeft {
        padding: 20px;
    }

    .pastProgVideoSec {
        min-height: 400px;
    }

    .react-tabs__tab {
        padding: 10px 16px;
        /* Reduced padding */
    }

    .feacProgramSec .sliderOne .programContent-inner {
        padding: 0px;
    }

    .pastProgram {
        margin-left: 50vw !important;
    }

    .networkSchoolSec .topHeading h2 {
        padding-bottom: 30px;
    }

    /* .ourPillarsSec .ourPillars-outer .pillars .bgPillarsImg {
        height: 400px;
    } */

    .ourPillarsSec .ourPillars-outer .content {
        opacity: 1;
    }


    .banner-content {
        bottom: 20px;
    }

    p {
        font-size: 14px;
    }

    h3 {
        font-size: 20px;
    }

    .column {
        width: 100%;
    }

    .oxfordBanner .banner-content h1,
    .secTitle,
    .fs-80 {
        font-size: 28px !important;
    }

    .fs-14 {
        font-size: 13px !important;
    }

    .fs-18 {
        font-size: 14px !important;
    }

    .fs-22 {
        font-size: 12px !important;
    }

    .fs-34 {
        font-size: 18px !important;
    }

    .fs-32 {
        font-size: 18px !important;
    }

    .fs-25 {
        font-size: 14px !important;
    }

    .fs-42 {
        font-size: 24px !important;
    }

    .secTitle {
        padding-bottom: 30px;
    }

    .p-80-20 {
        padding: 20px 20px !important;
    }

    a.past_movie {
        padding: 20px;
    }

    .intro-section,
    .eventSec,
    .conference,
    .partnerSec,
    .callToAction {
        padding: 40px 20px;
    }

    .intro-section {
        padding: 40px 0px !important;
    }

    .topHeading {
        padding-bottom: 30px;
    }

    .main-header .navbar .mobile-flap-nav-toggle {
        width: 30px;
        height: 30px;
    }

    .whyOxfordSec {
        padding: 60px 20px !important;
    }

    .whyOxfordSec .row {
        display: block;
    }

    .whyOxfordSec .inner-box {
        border-radius: 20px;
        padding: 30px;
    }

    .whyOxfordSec .inner-box .column:first-child {
        padding-bottom: 30px;
        margin-bottom: 30px;
        border-image: linear-gradient(to left, #fff, #66666680, #666666cc, #666666) 1;
        border-bottom: 2px solid;
    }

    .conference .confere-detail .row {
        display: block;
    }

    .confere-box {
        border-radius: 15px;
        margin-bottom: 30px !important;
    }

    .conference .confere-reg {
        border-radius: 15px;
        padding: 25px;
    }

    .eventSec .eventBox img {
        border-radius: 15px 15px 0 0;
        height: 255px;
    }

    .eventSec .eventBox {
        border-radius: 15px;
    }

    .eventSec .swiper {
        padding: 0 0px 60px;
    }

    .eventSec .eventBox .eventTitle {
        font-size: 20px;
    }

    .eventSec .swiper-button-prev,
    .eventSec .swiper-button-next {
        top: inherit;
        width: 35px;
        height: 35px;
        bottom: 0px;
    }

    .eventSec .swiper-button-prev {
        right: inherit;
        left: calc(50% - 45px);
    }

    .eventSec .swiper-button-next {
        right: calc(50% - 45px);
    }

    .eventSec .eventBox .eventContentBox {
        min-height: 224px !important;
    }

    .partnerSec .swiper-pagination {
        bottom: -20px !important;
    }

    .partnerSec .swiper-pagination .swiper-pagination-bullet {
        width: 6px;
        height: 6px;
    }

    .venueSpotSec .venueSpot-inner .image-gallery-thumbnail {
        width: 80px;
        margin: 0 5px;
    }

    .venueSpotSec .venueSpot-inner .image-gallery-thumbnails-wrapper {
        left: 10px;
    }

    .venueSpotSec .venueSpot-inner .image-gallery-slides {
        border-radius: 15px;
    }

    .venueSpotSec .venueSpot-inner .image-gallery-left-nav,
    .image-gallery-right-nav {
        width: 30px;
        height: 30px;
        line-height: 0px;
    }

    .venueSpotSec .venueSpot-inner .image-gallery-left-nav .image-gallery-svg,
    .image-gallery-right-nav .image-gallery-svg {
        height: 20px;
        width: 20px;
    }

    .venueSpotSec {
        padding: 40px 20px !important;
    }

    .venueSpotSec .venueSpot-inner .venueSpotContent {
        padding: 30px 0px 0px;
    }

    .venueSpotSec .venueSpot-inner .venueSpotContent h2 {
        font-size: 24px;
        padding-bottom: 15px;
    }

    .venueSpotSec {
        padding: 40px 20px 40px;
    }

    .testimonialSec {
        padding: 40px 20px 40px;
    }

    .testimonialSec .testimonialImg .titleWatch {
        font-size: 22px;
    }

    .reviewSec {
        padding: 40px 0 0;
    }

    /* .reviewSec .swiper-slide img {
        min-height: 450px;
    } */

    .reviewSec .reviewContent {
        /* padding: 10px 30px;
        bottom: 30px; */
        width: 100%;
        max-width: 700px;
    }

    /* .reviewSec .reviewContent .reviewTitle {
        max-width: 170px;
        margin: 0 auto;
    } */

    .reviewSec .reviewContent p {
        font-size: 14px;
    }

    .reviewSec .reviewContent p span {
        font-size: 16px;
    }

    .reviewSec .swiper-button-prev,
    .reviewSec .swiper-button-next {
        bottom: 40px;
        /* width: 12px;
        height: 18px; */
    }

    /* .reviewSec .swiper-button-prev {
        left: 50px;
    } */

    .reviewSec .swiper-button-prev:after,
    .reviewSec .swiper-button-next::after {
        width: 10px;
        height: 16px;
    }

    /* .reviewSec .swiper-button-next {
        right: 50px;
    } */

    .joinSec .row {
        display: block;
    }

    .joinSec {
        padding: 40px 20px;
    }

    .joinSec .logoImage {
        margin: 0 auto;
        min-width: 260px !important;
        width: 56% !important;
    }

    .joinSec .reportimpact-img {
        width: 100%;
        padding: 40px 0px;
    }

    .joinSec .logoImage img {
        width: 100%;
    }

    .joinSec .content-wrapperCol {
        width: 100%;
        padding: 0;
    }

    .joinSec .content-wrapperCol .content-wrapper .contentTitle {
        font-size: 24px;
        margin-bottom: 15px;
    }

    .joinSec .content-wrapperCol .content-wrapper .contentTitle:after {
        bottom: 5px;
        height: 3px;
    }

    .callToAction .callToAction-inner .callToActionLeft p {
        width: 100%;
        padding: 0px 0px 15px;
    }

    .callToAction .callToAction-inner {
        padding: 25px !important;
        border-radius: 15px;
    }

    .callToAction .callToAction-inner h5 {
        font-size: 22px;
    }

    .callToAction .callToAction-inner .row {
        display: block;
    }

    .registerBtn {
        font-size: 14px;
        padding: 12px 14px;
    }

    .faqSec .faqInner {
        padding: 20px;
        border-radius: 15px;
    }

    .faqSec .faqInnerLeft {
        margin-bottom: 30px;
        padding: 20px;
    }

    .faqSec .faqInnerLeft img {
        width: 120px;
    }

    .faqSec .faqInnerLeft .socialLinks img {
        width: 25px;
    }

    .faqSec .faqInner p {
        padding-bottom: 30px;
    }

    .faqSec .faqInnerRight .faqBlock .accordion .accordion__button {
        padding: 20px 25px 20px 10px;
    }

    .faqSec .faqInnerRight .faqBlock .accordion .accordion__button .faqTitle,
    .faqSec .faqInnerRight .faqBlock .accordion .accordion__panel p {

        font-size: 14px;
    }

    .faqSec .faqInnerRight .faqBlock .accordion .accordion__button .faqTitle img {
        margin-right: 10px;
    }

    .faqSec .faqInnerRight .faqBlock .accordion .accordion__panel {
        padding: 0px 20px 20px 44px;
    }

    .faqSec .faqInnerRight .faqBlock .accordion .accordion__button:before {
        width: 10px;
        height: 10px;
    }

    .header-btn {
        margin-left: 20px;
    }

    .ourPillarsSec .ourPillars-outer {
        grid-template-columns: repeat(1, 1fr);
    }

    .ourPillarsSec .ourPillars-outer .titleTop {
        position: absolute;
        top: 20px;
        left: 20px;
    }

    .feacProgramSec .swiper.feacProgram-outerCenter .swiper-wrapper,
    .feacProgramSec .swiper .swiper-wrapper {
        margin-left: 0;
    }

    /* .feacProgramSec .swiper-slide {
        width: 100% !important;
        height: auto;
    } */

    .feacProgramSec .sliderOne .programContent-inner {
        padding-right: 0;
    }

    .feacProgramSec .sliderOne .sliderBgImage {
        height: 220px;
        border-radius: 20px;
    }

    /* .feacProgramSec .sliderOne:after {
        border-radius: 20px;
    }
   */

    .feacProgramSec .sliderOne .programContent {
        padding: 0 20px 20px;
    }

    .networkSchoolSec .networkSchoolSlider-outer {
        /* width: 100%; */
    }

    .networkSchoolSec .networkSchoolSlider img {
        width: auto;
        max-height: 100px !important;
    }

    .orange_theme .faqSec .faqInnerLeft,
    .blue_theme .faqSec .faqInnerLeft {
        display: inline-block;
        padding: 60px 30px;
    }


    .networkSchoolSec .swiper {
        padding-bottom: 40px;
    }

    .blue_theme .registerBtn,
    .orange_theme .registerBtn {
        margin-bottom: 20px;
    }

    .blue_theme .callToAction .callToAction-inner {
        padding: 80px 40px !important;
    }

    .pastProgVideoSec .swiper-slide.swiper-slide-active .progVideos {
        width: 100% !important;
        height: 220px !important;
    }

    .orange_theme .faqSec .faqInnerLeft,
    .blue_theme .faqSec .faqInnerLeft {
        width: 100%;
    }

    .pastProgVideoSec .swiper-wrapper {
        padding-left: 40px;
        padding-bottom: 40px;
    }

    .skillSec .skills-outer {
        grid-template-columns: repeat(1, 1fr);
    }

    .skillSec p.subContent {
        font-size: 14px;
        width: auto;
    }

    .intreactionSec .intreactionVideo video {
        height: 400px;
    }

    .intreactionSec {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .intreactionSec .intreactionVideo:before {
        width: 95%;
    }

    .intreactionSec .intreactionInner {
        bottom: 125px;
    }

    .intreactionSec .swiper-button-prev,
    .intreactionSec .swiper-button-next {
        width: 30px;
        height: 30px;
    }

    .intreactionSec .swiper-button-prev {
        left: calc(50% - 40px);
    }

    .intreactionSec .swiper-button-next {
        right: calc(50% - 40px);
    }

    .intreactionSec .swiper-button-prev,
    .intreactionSec .swiper-button-next {
        bottom: 30px;
    }

    /* .testimonialHomeSec .testimonialImg .testimonialVideo {
        height: 500px;
    } */

    .partnerSec .swiper {
        padding-bottom: 20px;
    }

    /* .pastProgVideoSec .swiper-slide.swiper-slide-active {
        width: inherit !important;
    } */

    .pastProgVideoSec .swiper-slide-active .pastProgVideos:after {
        top: 13px;
        right: -7px;
        width: 80px;
    }

    .pastProgVideoSec .pastProgVideos .progVedioContent:before {
        height: 50px;
        width: 50px;
        top: -40px;
        left: -8px;
    }

    .pastProgVideoSec .pastProgVideos .progVedioContent {
        padding: 20px;
    }

    a.readMoreIconBtn {
        padding: 13px 11px;
    }

    .pastProgVideoSec .pastProgVideos .progVedioContent p {
        width: 310px;
    }

    span.swiper-pagination-bullet.swiper-pagination-bullet-active:after {
        width: 10px;
        height: 10px;
    }

    .pastProgVideoSec .progVedioContent a.readMoreIconBtn img.widthAuto {
        width: 16px;
    }

    span.swiper-pagination-bullet {
        width: 6px;
        height: 6px;
    }

    .networkSchoolSec .topHeading p {
        width: 100%;
    }

    .oxfordBanner .banner-content .muteBtn {
        margin-right: 20px;
        width: 30px;
        height: 30px;
    }

    .networkSchoolSec .networkSchoolSlider-outer::after,
    .networkSchoolSec .networkSchoolSlider-outer:before {
        display: none;
    }

    .network-school {
        padding-top: 100px !important;
    }

    .school-logo .school-logo-outer .bgwhite {
        gap: 30px;
        grid-template-columns: repeat(3, 1fr) !important;
    }


    .benefits .program-outer .program-innerRight>p {
        padding-bottom: 25px;
    }

    .download-brochure {
        font-size: 14px;
        padding: 10px 20px;
    }

    .benefits .program-innerRight a.registerBtn {
        margin: 0 0 20px;
        font-size: 14px;
        padding: 15px 20px;
    }

    .benefits .program-innerRight ul.freestructure {
        padding-bottom: 25px;
    }

    .programsSec .program-outer .program-innerLeft img {
        border-radius: 20px;
    }

    .contactPopup .contactForm {
        border-radius: 15px;
        padding: 50px 20px;
    }

    .contactPopup .contactForm input,
    .contactPopup .contactForm textarea,
    select {
        height: 50px;
        padding: 10px 20px;
        border-radius: 8px;
        margin-bottom: 15px;
        font-size: 14px;
    }

    .loaction {
        grid-template-columns: 1fr;
        gap: 0px;
    }

    .contactPopup .contactForm button.formSubmit {
        padding: 12px 30px;
        font-size: 14px;
    }

    .pillarBoxSec .pillarBox {
        display: block;
        border-radius: 20px;
        margin-bottom: 20px;
    }

    .pillarBoxSec .pillarBox img {
        width: 100%;
        border-radius: 20px 20px 0 0px !important;
    }

    .pillarBoxSec .pillarBox .pillarBoxContent {
        padding: 30px;
    }

    .pillarBoxSec .pillarBox:nth-child(2) {
        display: flex;
        flex-direction: column-reverse;
    }

    .programsSec .program-outer .program-innerRight ul.progarmDetailList {
        gap: 15px;
        grid-template-columns: 1fr;
        padding: 20px;
        margin: 20px 0px;
    }

    .programsSec .program-outer .program-innerRight .registerBtn {
        width: 140px;
        padding: 12px 20px !important;
    }

    .programsSec .program-outer .program-innerRight .registerBtn img {
        width: 16px;
    }

    .readMoreBtn {
        font-size: 14px;
    }

    .opportunitiesSec .opportunities-outer {
        grid-template-columns: 1fr;
    }

    html body .popup-content {
        width: 90%;
    }


}


@media (max-width: 480px) {
    .reviewSec .reviewContent {
        padding: 15px 30px;
        width: 365px;
    }
    .reviewSec .swiper-button-next {
        right: 40px;
    }
    .reviewSec .swiper-button-prev {
        left: 40px;
    }}
@media (max-width: 379px) {
   
    .reviewSec .swiper-button-next {
        right: 20px;
    }
    .reviewSec .swiper-button-prev {
        left: 20px;
}}










@media screen and (max-width: 400px) {


    


    .partners_logo_home img {
        width: 100%;
        /* Ensure logos fit within the slide */
    }




    .partnerSec .networkSchoolSlider-outer {
        width: 100%;
    }
}


@media screen and (max-width: 325px) {

    .header-btn {
        bottom: 18px !important;
    }


    button.menu_toggle {
       
        height: 33px;
        width: 31px;
      
        top: 29%;
        right: 14px !important;
      
    }












    .partners_logo_home img {
        width: 90%;
       
    }

    .registerBtn {
        font-size: 12px;
        padding: 11px 10px;
    }

    .intreactionInner .textWhite {
        font-size: 20px !important;
    }
}





@media only screen and (max-height: 950px) {


    


    .reviewSec .swiper-horizontal {
        overflow: hidden;
        touch-action: pan-y;
        /* max-height: 981px; */
    }

    h3 {
        font-size: 24px;
    }

    .reviewSec .reviewContent {
        bottom: 25px;
    }

    /* .ourPillarsSec .ourPillars-outer .pillars .bgPillarsImg{
        height: 450px !important;
    } */
    section.bgBlack.partnerSec.partnerHomeSec {
        padding: 50px 0px;
    }

    .intreactionSec .swiper-button-prev,
    .intreactionSec .swiper-button-next {
        bottom: 25px;
    }

    .swiper-horizontal {
        overflow: hidden;
        touch-action: pan-y;
        /* max-height: 881px; */
    }

    .eventSec .eventBox .eventContentBox {
        min-height: 283px !important
    }

    .mun_logo .logoImage img {
        width: 60%;
    }

    img {
        width: 100%;

    }

    .gray_theme .whyOxfordSec {
        padding: 50px 20px !important;
    }

    .testimonialSec {
        padding: 50px 20px;
    }

    .reviewSec {
        padding: 50px 0 0;
    }

    .green_theme .whyOxfordSec {
        padding: 50px 20px !important;
    }

    button.registerBtn {
        margin-bottom: 10px;
    }

    .venueSpotSec .venueSpot-inner .venueSpotContent {
        padding: 20px 40px 20px 140px;
    }

    /* .testimonialHomeSec .testimonialImg .testimonialVideo {
        height: 550px;
    } */

    .secTitle {
        padding-bottom: 40px;

    }

    /* 
    .swiper-slide.swiper-slide-visible.swiper-slide-fully-visible {
        max-height: 800px;
        width: 100%;
        object-fit: cover;
        overflow: hidden;
    } */


}



@media (max-height: 600px) {

    /* .reviewSec .reviewContent {
        width: 480px;
        bottom: 183px !important;
    } */
}


@media (max-height: 800px) {
    
/* .contactPopup .contactForm{
    height: 500px !important;
    max-height: 800px;
    overflow: scroll  !important;
    }
    
    .popup-content {
        width: 90vw !important;
        max-height: 85vh;
        top: 6px;
        bottom: 0%;
    } */



    /* .contactPopup .contactForm input,
.contactPopup .contactForm{
    height: 55px;
} */

    .pillarBox.empower.m-0 {
        min-height: 92.5vh;
    }

    /* .reviewSec .swiper-slide img {
        min-height: 450px !important;
    } */

    .pastProgVideoSec .pastProgVideos .progVedioContent {
        padding: 20px;
    }

    header {
        position: none;
    }

    .programsSec .program-outer {
        padding-top: 0px;
        padding-bottom: 0px;
        /* margin-top: 30px; */

    }

    .topHeading {
        padding-bottom: 40px;
    }

    .main-header {
        background-color: #000;
    }

    .p-80-20 {
        padding: 40px 20px !important;
    }

    /* .swiper-horizontal {
        max-height: 600px !important;
    } */

    .intreactionVideo img {
        height: 600px !important;
        object-fit: cover;
    }

    /* .testimonialHomeSec .testimonialImg .testimonialVideo{
        height: 450px;
    } */

    /* .ourPillarsSec .ourPillars-outer .pillars .bgPillarsImg{
        height: 450px !important;
    } */

    /* .eventSec .eventBox .eventContentBox {
        min-height: 223px !important
    } */

    .venueSpotSec .venueSpot-inner img {
        width: 541px;
    }

    .whyOxfordSec {
        padding: 40px 20px !important;
    }

    .mun_logo .logoImage img {
        width: 60%;
    }

    img {
        width: 100%;

    }

    .gray_theme .whyOxfordSec {
        padding: 50px 20px !important;
    }

    .testimonialSec {
        padding: 50px 20px;
    }

    .reviewSec {
        padding: 50px 0 0;
    }

    .green_theme .whyOxfordSec {
        padding: 50px 20px !important;
    }

}





/* ////////////////////////////////////////////////////// */

.verticalVideo.oxfordBanner {
    background-color: #000;
}

.verticalVideo .sliderBgImage {
    width: auto;
    margin: 0 auto !important;
    display: block;
}

@media (max-width: 1440px) {

    .programsSec .program-outer .program-innerRight ul.progarmDetailList {
        grid-template-columns: 1fr;
    }
}

.mt-40 {
    margin-top: 40px;
}

@media (max-width: 767px) {

    .reviewTitle {
        font-size: 20px !important;
    }
    .reviewSec .swiper-slide img {
        min-height: 400px;
    }

    /* Pillar page only for mobile Spacing for heading  */

    .pillars_banner {
        position: relative;
        background: #000;
        padding-top: 20px;
        padding-bottom: 20px;
        margin-top: -5px;
    }

    .pillars_banner .banner-content {
        width: 100%;
        text-align: center;
        position: relative;
        padding-bottom: 10px;
        padding-top: 50px;
    }

    .pillars_banner .mute {
        position: absolute;
        top: -40px;
        right: 10px;
    }

    .pillars_banner .unmute {
        position: absolute;
        top: -40px;
        right: 10px;
    }

    /* -------- */

    span.error-message{
        bottom: 7px;
        font-size: 11px;
    }

    /* .pastProgVideoSec .swiper-slide.swiper-slide-active {
    width: 100% !important; 
} */
    .mt-40 {
        margin-top: 20px;
    }
    .ehis,
    .nmims {
        font-size: 12px;
        padding: 10px 10px;
    }
    .movement_box .row {
        padding: 40px 0px !important;
    }

    .movement_box .logoImage {
        padding-bottom: 20px;
        min-width: 100% !important;
        width: 100% !important;
    }

    h2.Testimonial_ {
        padding-bottom: 40px;
    }

    .footer .copyright p {
        text-align: center;
    }

    form.form-error:after {
        left: 0px;
        color: #fff;
        font-size: 12px;
        background: #f00;
        padding: 3px 3px;
        line-height: 21px;
        width: 100%;
    }
    .pillarBox.collaborate.m-0 {
        min-height: 72.5vh;
    }

}

@media (max-width: 330px) {

    .registerBtn.joinbtn {
        margin: 15px 0px;
        display: block !important;
    }

    .registerBtn {
        display: block !important;
        width: 100% !important;
        text-align: center;
    }
}